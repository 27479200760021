import cloneDeep from "lodash/cloneDeep";
import {
  applianceNetworksKeyedFetcher,
  applianceResourceFetchers,
  appliancesKeyedFetcher,
} from "state/cluster/services/create";
import { clusterCreateEdgeMachinesDrawer } from "state/cluster/services/edgemachines";
import clusterFormActions from "../form";
import { getNicsByDeviceUid } from "state/cluster/selectors/create";
import { edgeHostDetailsFetcher } from "state/cluster/services/create/flows/edge";

export function openClusterCreateEdgeMachinesDrawer() {
  return (dispatch) => {
    clusterCreateEdgeMachinesDrawer.open().then(() => {});
  };
}

export function addNewVirtualDevice(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].edgeHosts.push({ hostUid: "" });
    dispatch(appliancesKeyedFetcher.key(`${index}`).fetch());
    if (index === 0 && nodePools[0].edgeHosts.length > 1) {
      nodePools[0].edgeHosts[0].primary = false;
    }
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
  };
}

export function removeVirtualDevice({ poolIndex, deviceIndex }) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[poolIndex].edgeHosts.splice(deviceIndex, 1);
    const cloudType = getState().forms.cluster.data.cloudType;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}`,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}`,
      })
    );

    applianceResourceFetchers.forEach((fetcher) =>
      dispatch(fetcher.key(`${poolIndex}.${deviceIndex}`).fetch())
    );
    if (cloudType === "edge-native") {
      nodePools.forEach((_, index) =>
        dispatch(appliancesKeyedFetcher.key(`${index}`).fetch())
      );
    } else {
      dispatch(appliancesKeyedFetcher.key(`${poolIndex}`).fetch());
    }
  };
}

export function onArchitectureChange(index, value) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index] = {
      ...nodePools[index],
      architecture: value,
      edgeHosts: [],
    };

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${index}`,
      })
    );

    dispatch(appliancesKeyedFetcher.key(`${index}`).fetch());
  };
}

function clearStaticIpFieldsError(index, poolIndex) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.edgeHosts.${index}.ip`,
      })
    );
    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.edgeHosts.${index}.subnet`,
      })
    );
    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.edgeHosts.${index}.gateway`,
      })
    );
    dispatch(
      clusterFormActions.clearFieldErrors({
        module: "cluster",
        field: `nodePools.${poolIndex}.edgeHosts.${index}.dns`,
      })
    );
  };
}

export function onStaticIPToggle({ isEnabled, index, poolIndex }) {
  return (dispatch, getState) => {
    if (!isEnabled) {
      dispatch(clearStaticIpFieldsError(index, poolIndex));
    }

    const formData = getState()?.forms?.cluster?.data || {};
    const nodePool = { ...formData?.nodePools[poolIndex] };

    const edgeHost = nodePool?.edgeHosts?.[index] || {};
    edgeHost.enableStaticIp = isEnabled;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}`,
        value: nodePool,
      })
    );
  };
}

export function onNicChange(nicName, index, poolIndex) {
  return (dispatch, getState) => {
    dispatch(clearStaticIpFieldsError(index, poolIndex));
    const formData = getState()?.forms?.cluster?.data || {};
    const nodePool = { ...formData?.nodePools[poolIndex] };
    const edgeHost = nodePool?.edgeHosts[index] || {};
    const { hostUid } = edgeHost;
    const nicsByDevice = getNicsByDeviceUid(getState());
    const currentEdgeHostNics = nicsByDevice[hostUid] || [];
    const selectedNic = currentEdgeHostNics.find(
      (nic) => nic.nicName === nicName
    );
    nodePool.edgeHosts.splice(index, 1, {
      ...edgeHost,
      nicName,
      ip: selectedNic?.ip,
      gatewa: selectedNic?.gateway || "",
      subnet: selectedNic?.subnet || "",
      dns: selectedNic?.dns || [],
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}`,
        value: nodePool,
      })
    );
  };
}

export function onApplianceChange({ poolIndex, deviceIndex, value }) {
  return async (dispatch, getState) => {
    const formData = getState().forms.cluster.data;
    const nodePools = [...cloneDeep(formData.nodePools)];
    const edgeHostToChange = nodePools[poolIndex].edgeHosts[deviceIndex];
    const appliances = appliancesKeyedFetcher
      .key(`${poolIndex}`)
      .selector(getState());
    const selectedAppliance = (appliances?.result || []).find(
      (appliance) => appliance.metadata.uid === value
    );

    edgeHostToChange.hostUid = value;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    await dispatch(edgeHostDetailsFetcher.key(value).fetch());

    const nicsByDevice = getNicsByDeviceUid(getState());
    const currentEdgeHostNics = nicsByDevice[value];

    if (formData.cloudType === "edge-native" && !!currentEdgeHostNics?.length) {
      const defaultNic =
        currentEdgeHostNics.find((nic) => nic?.isDefault) ||
        currentEdgeHostNics[0];

      edgeHostToChange.nicName = defaultNic?.nicName;

      edgeHostToChange.ip = defaultNic?.ip;
      edgeHostToChange.gateway = defaultNic?.gateway || "";
      edgeHostToChange.subnet = defaultNic?.subnet || "";
      edgeHostToChange.dns = defaultNic?.dns || [];

      dispatch(
        clusterFormActions.clearFieldErrors({
          module: "cluster",
          field: `nodePools.${poolIndex}.edgeHosts.${deviceIndex}`,
        })
      );
    }

    if (formData.cloudType === "libvirt") {
      edgeHostToChange.networkType = "";
      edgeHostToChange.networks = [];
      edgeHostToChange.dataStoragePool = "";
      edgeHostToChange.sourceStoragePool = "";
      edgeHostToChange.targetStoragePool = "";
      nodePools[poolIndex].gpuVendor = "";
      nodePools[poolIndex].gpuModel = "";
      edgeHostToChange.status = selectedAppliance?.status?.state;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    if (formData.cloudType === "libvirt") {
      applianceResourceFetchers.forEach((fetcher) =>
        dispatch(fetcher.key(`${poolIndex}.${deviceIndex}`).fetch())
      );
      dispatch(appliancesKeyedFetcher.key(`${poolIndex}`).fetch());
    } else {
      nodePools.forEach((_, index) =>
        dispatch(appliancesKeyedFetcher.key(`${index}`).fetch())
      );
    }
  };
}

export function onNetworkTypeChange({ poolIndex, deviceIndex, value }) {
  return (dispatch, getState) => {
    const nodePools = [...cloneDeep(getState().forms.cluster.data.nodePools)];
    nodePools[poolIndex].edgeHosts[deviceIndex].networkType = value;
    nodePools[poolIndex].edgeHosts[deviceIndex].networks = [];

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      applianceNetworksKeyedFetcher.key(`${poolIndex}.${deviceIndex}`).fetch()
    );
  };
}

export function onEdgeHostChange({ poolIndex, edgeHosts }) {
  return function thunk(dispatch) {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.edgeHosts`,
        value: edgeHosts,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}`,
      })
    );
  };
}
