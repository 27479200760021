import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import { generatePath } from "react-router";
import PageHeader from "components/common/layouts/App/PageHeader";
import { NavLink, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { winterMist, white, nearBlack, platinum } from "utils/constants/colors";
import { SETTINGS } from "utils/constants/routes";
import { FLAGS } from "utils/constants/flags";
import { isOnPremApplication } from "state/auth/selectors";
import flags from "services/flags";
import { MAX_CONTENT_WIDTH } from "components/styled/mixins";
import Icon from "components/ui/Icon";
import { faAngleRight } from "@fortawesome/sharp-solid-svg-icons";
import { getCurrentContext } from "state/auth/selectors/common";
import { getCurrentUser } from "state/auth/selectors";
import { Collapse } from "antd";
import {
  projectSettingsCache,
  tenantSettingsCache,
} from "services/localstorage/cache";
import {
  CATEGORIES,
  projectSettingcategories,
  tenantSettingsCategories,
  groupRoutes,
} from "./SettingsCategory";

const { Panel } = Collapse;

const SIDEBAR_WIDTH = 260;

const MainWrap = styled.div`
  height: calc(100% + 32px);
  display: flex;
  margin: -16px -32px;
  overflow: hidden;
`;

const SidebarMenu = styled.div`
  border-right: 1px solid ${winterMist};
  border-top: 1px solid ${platinum};
  max-width: ${SIDEBAR_WIDTH}px;
  background: #edeef4;
  width: 100%;
  margin-bottom: -16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Title = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${nearBlack};
    padding: 4.5px 24px;
    margin: 0 34px;
    background-color: ${white};
    color: ${nearBlack};
    border-left: 1px solid ${platinum};

    ${(props) =>
      props.isAdmin &&
      css`
        margin: 0 38px;
      `}
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  max-width: ${parseInt(MAX_CONTENT_WIDTH.replace("px", "")) -
  SIDEBAR_WIDTH +
  26}px;
  background-color: white;
  padding: 32px;
`;

const ContentWrap = styled.div`
  flex-grow: 1;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  border-top: 1px solid ${platinum};
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #545f7e;
  background: #edeef4;

  :hover {
    color: #545f7e;
    background: #e1e2e9;
  }

  :active {
    color: #545f7e;
    background: #d8dade;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #494492;
      background-color: ${white};
      border-right: 4px solid #494492;
      &:hover {
        background-color: ${white};
      }
    `};
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 16px;
  .ant-collapse-item {
    border-bottom: 1px solid #dee1ea;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: #111626;
    font-weight: 500;
    background: #f7f9ff;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-right: 8px;
    vertical-align: 0px;
  }
  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }
`;

const StyledIcon = styled(Icon)`
  transition: transform 0.3s ease;
  color: #545f7e;
`;
export const contentRef = React.createRef();

function Settings({
  history,
  children,
  currentContext,
  isOnPremApp,
  currentUser,
}) {
  const isProjectUpdatePermission =
    currentContext?.permissions?.includes("project.update");

  // Check whether tenant settings or project settings
  const settingsCache = currentContext?.isAdmin
    ? tenantSettingsCache
    : projectSettingsCache;
  const categories = currentContext?.isAdmin
    ? tenantSettingsCategories
    : projectSettingcategories;
  const currentUserId = currentUser?.metadata?.uid;
  const storageKey = currentContext?.isAdmin
    ? `${currentUserId}`
    : `${currentUserId}-${currentContext?.projectUid}`;

  let activeKey = "/";
  const location = history.location.pathname;
  if (location.startsWith("/projects/")) {
    activeKey = location.split("/")[4];
  }
  if (location.startsWith("/admin/")) {
    activeKey = location.split("/")[3];
  }

  const PROJECT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    flags.has(FLAGS.BACKUP) && {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "sshkeys",
      path: SETTINGS.SSH_KEYS,
      label: i18n.t("SSH Keys"),
      category: CATEGORIES.SECURITY,
    },
    isProjectUpdatePermission &&
      flags.has(FLAGS.ALERTS) && {
        key: "alerts",
        path: generatePath(SETTINGS.ALERTS, { tab: "email" }),
        label: i18n.t("Alerts"),
        category: CATEGORIES.PLATFORM,
      },
    {
      key: "macros",
      path: SETTINGS.MACROS,
      label: i18n.t("Macros"),
      category: CATEGORIES.PLATFORM,
    },
    isProjectUpdatePermission && {
      key: "platform-settings",
      path: SETTINGS.PLATFORM_SETTINGS,
      label: i18n.t("Platform Settings"),
      category: CATEGORIES.PLATFORM,
    },
  ].filter(Boolean);

  const TENANT_ROUTES = [
    {
      key: "cloudaccounts",
      path: SETTINGS.CLOUD_ACCOUNTS,
      label: i18n.t("Cloud Accounts"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "cloudrates",
      path: SETTINGS.CLOUD_RATES,
      label: i18n.t("Cloud Rates"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "registries",
      path: generatePath(SETTINGS.REGISTRIES, { tab: "helm" }),
      label: i18n.t("Registries"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "privatecloudgateways",
      path: SETTINGS.PRIVATE_CLOUD_GATEWAYS,
      label: i18n.t("Private Cloud Gateways"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "backuplocations",
      path: SETTINGS.BACKUP_LOCATIONS,
      label: i18n.t("Backup Locations"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "sshkeys",
      path: SETTINGS.SSH_KEYS,
      label: i18n.t("SSH Keys"),
      category: CATEGORIES.SECURITY,
    },
    {
      key: "sso",
      path: generatePath(SETTINGS.SSO, { tab: "configure" }),
      label: i18n.t("SSO"),
      category: CATEGORIES.SECURITY,
    },
    {
      key: "password-policy",
      path: SETTINGS.PASSWORD_POLICY,
      label: i18n.t("Password Policy"),
      category: CATEGORIES.SECURITY,
    },
    !isOnPremApp && {
      key: "plandetails",
      path: SETTINGS.PLAN_DETAILS,
      label: i18n.t("Plan Details"),
      category: CATEGORIES.SUBSCRIPTION,
    },
    !isOnPremApp && {
      key: "billingdetails",
      path: SETTINGS.BILLING_DETAILS,
      label: i18n.t("Billing Details"),
      category: CATEGORIES.SUBSCRIPTION,
    },
    {
      key: "certificates",
      path: SETTINGS.CERTIFICATES,
      label: i18n.t("Certificates"),
      category: CATEGORIES.PLATFORM,
    },
    {
      key: "audittrails",
      path: SETTINGS.AUDIT_TRAILS,
      label: i18n.t("Audit Trails"),
      category: CATEGORIES.INFRASTRUCTURE,
    },
    {
      key: "apikeys",
      path: SETTINGS.API_KEYS,
      label: i18n.t("API Keys"),
      category: CATEGORIES.SECURITY,
    },
    {
      key: "macros",
      path: SETTINGS.MACROS,
      label: i18n.t("Macros"),
      category: CATEGORIES.PLATFORM,
    },
    {
      key: "resource-limits",
      path: SETTINGS.RESOURCE_LIMITS,
      label: i18n.t("Resource Limits"),
      category: CATEGORIES.PLATFORM,
    },
    {
      key: "platform-settings",
      path: SETTINGS.PLATFORM_SETTINGS,
      label: i18n.t("Platform Settings"),
      category: CATEGORIES.PLATFORM,
    },
    {
      key: "registration-tokens",
      path: SETTINGS.REGISTRATION_TOKENS,
      label: i18n.t("Registration Tokens"),
      category: CATEGORIES.SECURITY,
    },
    {
      key: "developer-settings",
      path: SETTINGS.DEVELOPER_SETTINGS,
      label: i18n.t("Developer Settings"),
      category: CATEGORIES.SECURITY,
    },
    {
      key: "filters",
      path: SETTINGS.FILTERS,
      label: i18n.t("Filters"),
      category: CATEGORIES.PLATFORM,
    },
  ].filter(Boolean);

  const routes = useMemo(
    () => (currentContext?.isAdmin ? TENANT_ROUTES : PROJECT_ROUTES),
    [currentContext, TENANT_ROUTES, PROJECT_ROUTES]
  );

  const categorizedRoutes = useMemo(
    () => groupRoutes(routes, categories),
    [routes, categories]
  );

  const getInitialPanels = () => {
    const savedPanels = settingsCache.get(storageKey) || [];
    let activeCategory = null;

    for (let category of categorizedRoutes) {
      for (let route of category.routes) {
        if (route.key === activeKey) {
          activeCategory = category.categoryKey;
          break;
        }
      }
      if (activeCategory) break;
    }

    if (!activeCategory && categorizedRoutes.length > 0) {
      activeCategory = categorizedRoutes[0].categoryKey;
    }

    if (!savedPanels.includes(activeCategory)) {
      savedPanels.push(activeCategory);
    }

    return { savedPanels, activeCategory };
  };

  const { savedPanels, activeCategory } = getInitialPanels();
  const [activePanels, setActivePanels] = useState(savedPanels);
  const [routeExpandedPanel, setRouteExpandedPanel] = useState(
    settingsCache.get(storageKey)?.includes(activeCategory)
      ? null
      : activeCategory
  );

  const handlePanelChange = (keys) => {
    let filteredKeys = keys;
    if (routeExpandedPanel) {
      if (!keys.includes(routeExpandedPanel)) {
        setRouteExpandedPanel(null);
      } else {
        filteredKeys = keys.filter((key) => key !== routeExpandedPanel);
      }
    }
    setActivePanels(keys);
    settingsCache.set(storageKey, filteredKeys);
  };

  const activeLabel = routes.find(
    (navLink) => navLink.key === activeKey
  )?.label;

  function renderNavLinks(navLink) {
    return (
      <StyledNavLink
        isSelected={activeKey === navLink.key}
        key={navLink.key}
        to={navLink.path}
        data-qa-type="settings-menu-item"
        data-qa-key={navLink.key}
      >
        {navLink.label}
      </StyledNavLink>
    );
  }

  const title = currentContext?.isAdmin
    ? i18n.t("Tenant Settings")
    : i18n.t("Project Settings");

  return (
    <>
      <PageHeader
        title={
          <Title isAdmin={currentContext?.isAdmin}>
            {title}{" "}
            <span>{i18n.t("Manage {{activeLabel}}", { activeLabel })}</span>
          </Title>
        }
        tabTitleContent={{
          activeTab: activeLabel,
          pageTitle: title,
        }}
        breadcrumbs={[{ label: title }]}
        withBack={false}
      />
      <MainWrap>
        <SidebarMenu>
          <StyledCollapse
            onChange={handlePanelChange}
            activeKey={activePanels}
            bordered={false}
            expandIcon={({ isActive }) => (
              <StyledIcon
                awesome={faAngleRight}
                transform={{ rotate: isActive ? 90 : 0 }}
              ></StyledIcon>
            )}
          >
            {categorizedRoutes.map((category) => (
              <Panel
                header={category.categoryName}
                key={category.categoryKey}
                data-qa={category.categoryKey}
              >
                {category.routes.map(renderNavLinks)}
              </Panel>
            ))}
          </StyledCollapse>
        </SidebarMenu>
        <ContentWrap ref={contentRef}>
          <Content>{children}</Content>
        </ContentWrap>
      </MainWrap>
    </>
  );
}

export default connect((state) => ({
  currentUser: getCurrentUser(state),
  currentContext: getCurrentContext(state),
  isOnPremApp: isOnPremApplication(state),
}))(withRouter(Settings));
