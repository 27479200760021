import React, { useState, useMemo } from "react";
import { faCheckCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FieldWrap } from "components/styled/Field";
import { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import { useTranslation } from "react-i18next";
import flags from "services/flags";
import styled from "styled-components";
import { midGray, white } from "utils/constants/colors";
import { FLAGS } from "utils/constants/flags";
import Fields from "components/ui/Fields";
import Drawer from "components/ui/Drawer";
import ModalService from "services/modal";
import ListActions from "modules/list/actions";
import { connect, useDispatch } from "react-redux";
import createList, { Blocks } from "modules/list";
import { EdgeHostFiltersComponent } from "pages/clusters/components/edge/EdgeMachinesFilters";
import { useAppliancesColumns } from "pages/clusters/listing/EdgeMachines";
import { EdgeMachineSchema } from "utils/schemas";
import { parseAppliances } from "utils/parsers";
import api from "services/api";
import { getEntity } from "utils/entities";
import { TagsV2 } from "components/common/Tags";
import AddNewItem from "components/styled/AddNewItem";
import Status from "components/styled/Status/new";
import HealthStatusMessage from "components/styled/HealthStatusMessage";
import { getStoreEntity } from "services/store";
import { FilterBounds } from "pages/clusters/components/edge/EdgeMachinesFilters";
import i18next from "i18next";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #f7f9ff;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;

  :last-child {
    margin-bottom: 0;
    border-bottom: unset;
  }

  ${TextButton} {
    color: ${midGray};
    width: 48px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowFieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: ${(props) => (props.isVirtualized ? "30%" : "400px")};
    margin-right: 20px;
    margin-bottom: 10px;
  }

  > ${FieldWrap} {
    margin-top: 0;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const CardWrap = styled.div`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: ${white};
  padding: 16px;
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeathWrap = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

function EdgeHostCard({ edgeHost, wrapRef }) {
  return (
    <CardWrap>
      <CardTitle>
        <span>{edgeHost.hostUid || edgeHost.metadata?.name}</span>
        <HeathWrap>
          <Status status={edgeHost?.status?.state} />
          <HealthStatusMessage
            state={edgeHost?.status?.health?.state}
            message={edgeHost?.status?.health?.message}
            compact
          />
        </HeathWrap>
      </CardTitle>
      <TagsV2
        tags={edgeHost.metadata?.labels}
        collisionBoundaryRef={wrapRef?.current}
      />
    </CardWrap>
  );
}

const ConnectedEdgeHostCard = connect((state, ownProps) => ({
  edgeHost: getEntity(() => ownProps.guid, EdgeMachineSchema)(state),
}))(EdgeHostCard);

const getNicNameOptions = (nics = []) => {
  return [
    {
      label: "Auto",
      value: "auto",
      description: i18next.t(
        "Kubernetes API and the rest of its resources will be accessible from any networks attached to the edge host"
      ),
    },
    ...nics.map((nic) => ({
      label: `${nic.nicName} ${nic.ip ? `(${nic.ip})` : ""}`,
      value: nic.nicName,
      description: nic.description || (
        <>
          <div>
            <strong>IP:</strong> {nic.ip || "N/A"}
          </div>
          <div>
            <strong>Gateway:</strong> {nic.gateway || "N/A"}
          </div>
          <div>
            <strong>Subnet:</strong> {nic.subnet || "N/A"}
          </div>
          <div>
            <strong>MAC Address:</strong> {nic.macAddr || "N/A"}
          </div>
          {nic.isDefault ? (
            <div>
              <strong>Is Default: </strong> <Icon awesome={faCheckCircle} />
            </div>
          ) : null}
        </>
      ),
    })),
  ];
};

function DeviceRow({
  index,
  name,
  edgeHost,
  nics = [],
  isTwoNode,
  onRemove,
  onChange,
  isOverlayEnabled,
  isConfigurable = false,
  wrapRef,
}) {
  const isDisabled = !isOverlayEnabled && !isConfigurable;
  const { t } = useTranslation();
  function getFieldName(fieldName) {
    const currentPath = name ? `${name}.` : "";
    if (!fieldName) {
      return `${currentPath}${index}`;
    }
    return `${currentPath}${index}.${fieldName}`;
  }

  let hostType = "";
  if (isTwoNode) {
    if (index === 0) {
      hostType = t("(Leader)");
    }

    if (index === 1) {
      hostType = t("(Follower)");
    }
  }

  const hasOwnNics = nics.filter((nic) => nic.value !== "auto").length;
  const isAuto = edgeHost.nicName === "auto";

  function onNicChange(nicName) {
    const nic = nics.find((n) => n.nicName === nicName);
    if (nicName === "auto") {
      onChange({
        ...edgeHost,
        nicName,
        enableStaticIp: false,
      });
      return;
    }
    if (nic && edgeHost.enableStaticIp) {
      onChange({
        ...edgeHost,
        nicName,
        ip: nic.ip,
        gateway: nic.gateway,
        subnet: nic.subnet,
        dns: nic.dns,
      });
      return;
    }

    onChange({
      ...edgeHost,
      nicName,
    });
  }

  function onStaticIPToggle(isEnabled) {
    if (!isEnabled) {
      onChange({
        ...edgeHost,
        enableStaticIp: isEnabled,
        ip: "",
        gateway: "",
        subnet: "",
        dns: [],
      });
      return;
    }

    const nic = nics.find((n) => n.nicName === edgeHost.nicName);
    onChange({
      ...edgeHost,
      enableStaticIp: isEnabled,
      ip: nic?.ip || "",
      gateway: nic?.gateway || "",
      subnet: nic?.subnet || "",
      dns: nic?.dns || [],
    });
  }

  const fields = {
    hostName: getFieldName("hostName"),
    nicName: getFieldName("nicName"),
    enableStaticIp: getFieldName("enableStaticIp"),
    ip: getFieldName("ip"),
    gateway: getFieldName("gateway"),
    subnet: getFieldName("subnet"),
    dns: getFieldName("dns"),
  };

  return (
    <Wrapper key={`appliances_${index}`}>
      <Container>
        <Section>
          <RowFieldsWrapper>
            <ConnectedEdgeHostCard
              guid={edgeHost.guid}
              hostType={hostType}
              wrapRef={wrapRef}
            />
            <Fields.Input
              label={t("Host Name")}
              required={false}
              name={fields.hostName}
              data-qa={fields.hostName}
              disabled={!isConfigurable}
            />
          </RowFieldsWrapper>
        </Section>
        {!hasOwnNics ? null : (
          <Section>
            <RowFieldsWrapper>
              <Fields.Select
                label={t("NIC Name")}
                name={fields.nicName}
                data-qa={fields.nicName}
                options={getNicNameOptions(nics)}
                showSelectedDescription
                onChange={onNicChange}
                disabled={!isConfigurable}
              />
            </RowFieldsWrapper>
            {isAuto ? null : (
              <>
                <RowFieldsWrapper>
                  <Fields.Switch
                    label={t("Static IP")}
                    name={fields.enableStaticIp}
                    data-qa={fields.enableStaticIp}
                    required={false}
                    onChange={onStaticIPToggle}
                    disabled={isDisabled}
                  />
                </RowFieldsWrapper>
                {edgeHost.enableStaticIp ? (
                  <RowFieldsWrapper>
                    <Fields.Input
                      label={t("IP Address")}
                      required={true}
                      name={fields.ip}
                      data-qa={fields.ip}
                      identifier={fields.ip}
                      disabled={isDisabled}
                    />
                    <Fields.Input
                      label={t("Default gateway")}
                      required={true}
                      name={fields.gateway}
                      data-qa={fields.gateway}
                      identifier={fields.gateway}
                      disabled={isDisabled}
                    />
                    <Fields.Input
                      label={t("Subnet mask")}
                      required={true}
                      name={fields.subnet}
                      data-qa={fields.subnet}
                      identifier={fields.subnet}
                      disabled={isDisabled}
                    />
                    <Fields.Select
                      label={t("DNS Server")}
                      required={true}
                      name={fields.dns}
                      data-qa={fields.dns}
                      identifier={fields.dns}
                      mode="tags"
                      open={false}
                      placeholder={t("comma-separated IPs (e.g: 8.8.8.8)")}
                      tokenSeparators={[",", " "]}
                      helperText={t(
                        "Note: the usage of 8.8.8.8 as an example DNS server is applicable only when there is network access to the public internet. In private environments without internet access, a reachable DNS server must be provided instead."
                      )}
                    />
                  </RowFieldsWrapper>
                ) : null}
              </>
            )}
          </Section>
        )}
      </Container>
      <TextButton
        data-qa="delete-device-row"
        onClick={() => onRemove(getFieldName())}
      >
        <Icon awesome={faTrashAlt} />
      </TextButton>
    </Wrapper>
  );
}

const DevicesWrapper = styled.div`
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #dee1ea;
  background: ${white};
`;

function Devices({
  name,
  value = [],
  onChange,
  nicsByHost = {},
  isMasterPool,
  isOverlayEnabled,
  onOpen,
  nodePoolsWrapperRef,
}) {
  const { t } = useTranslation();

  return (
    <DevicesWrapper>
      {value.map((edgehost, index) => (
        <DeviceRow
          key={index}
          index={index}
          name={name}
          nics={nicsByHost[edgehost.hostUid] || []}
          edgeHost={edgehost}
          isMasterPool={isMasterPool}
          isOverlayEnabled={isOverlayEnabled}
          isConfigurable={edgehost.isConfigurable}
          value={value}
          isTwoNode={
            flags.has(FLAGS.EDGE_TWO_NODE) && isMasterPool && value.length === 2
          }
          onRemove={() => {
            const updatedValue = [...value];
            updatedValue.splice(index, 1);
            onChange?.(updatedValue);
          }}
          onChange={(updatedHost) => {
            const updatedValue = [...value];
            updatedValue[index] = updatedHost;
            onChange?.(updatedValue);
          }}
          wrapRef={nodePoolsWrapperRef}
        />
      ))}
      <Fields.Error name={name} />
      <AddNewItem data-qa="add-edge-hosts" onClick={onOpen}>
        {t("Add edge hosts")}
      </AddNewItem>
    </DevicesWrapper>
  );
}
function getFilterPayload(filters = []) {
  return {
    conjunction: "and",
    filters: [
      {
        property: "unused",
        type: "bool",
        condition: {
          bool: {
            value: false,
          },
        },
      },
      // duplicated
      ...filters.map(({ values, property, operator }) => ({
        property,
        type: "string",
        condition: {
          string: {
            operator,
            negation: false,
            match: {
              conjunction: property === "tags" ? "and" : "or",
              values,
            },
            ignoreCase: false,
          },
        },
      })),
    ],
  };
}

const NegatePadding = styled.div`
  margin: -24px;
`;

export function createDeviceListingSelection({ name, statusOptions }) {
  const moduleName = `${name}-device-selection`;
  const modalService = new ModalService(moduleName);
  const listingActions = new ListActions({
    schema: [EdgeMachineSchema],
    hasPagination: true,
    persistFilters: true,
    defaultQuery: {
      search: "",
      limit: 25,
      states: [],
      tags: [],
      architecture: "",
    },
    initialQuery() {
      return {
        limit: 25,
      };
    },
    async fetchData(query) {
      const {
        offset,
        limit,
        continue: continueToken,
        states,
        search,
        architecture,
        tags,
      } = query;
      const apiUrl = "v1/dashboard/edgehosts/search";
      const edgeHostStates = states || [];
      const filters = [];

      if (search) {
        filters.push({
          property: "name",
          values: [search],
          operator: "contains",
        });
      }

      if (edgeHostStates.length) {
        filters.push({
          property: "state",
          values: edgeHostStates,
          operator: "eq",
        });
      }

      if (architecture) {
        filters.push({
          property: "architecture",
          values: [architecture],
          operator: "eq",
        });
      }

      if (tags) {
        filters.push({
          property: "tags",
          values: tags.map((tag) => {
            return tag.includes(":")
              ? tag
                  .split(":")
                  ?.map((t) => t?.trim())
                  ?.join(":")
              : tag;
          }),
          operator: "eq",
        });
      }

      const payload = {
        filter: {
          conjuction: "and",
          filterGroups: [getFilterPayload(filters)],
        },
        sort: [{ field: "creationTimestamp", order: "desc" }],
      };

      const continueQueryParam = continueToken
        ? `&continue=${continueToken}`
        : "";

      const data = await api.post(
        `${apiUrl}?limit=${limit}&offset=${offset || 0}${continueQueryParam}`,
        payload
      );

      return {
        ...data,
        items: parseAppliances(data?.items || []),
      };
    },
  });

  const ListingModule = createList({
    schema: [EdgeMachineSchema],
    actions: listingActions,
    hasPagination: true,
  });

  const additionalColumnConfigs = {
    machineId: {
      columnState: { locked: true, fixed: "left" },
    },
  };

  function EdgeHostsTable({ devicesInUse = [], selected, onSelect }) {
    const columns = useAppliancesColumns({
      showActions: false,
      additionalColumnConfigs,
    });

    const { height } = FilterBounds.useBounds() || {};
    const offsetHeader = useMemo(() => {
      return height - 26;
    }, [height]);

    return (
      <Blocks.Table
        showColumnManager={true}
        data-qa="edge-hosts-table"
        columns={columns}
        scroll={{ x: "1300px" }}
        thOverflow={true}
        resizable={true}
        sticky={{ offsetHeader }}
        rowSelection={{
          type: "checkbox",
          preserveSelectedRowKeys: true,
          selectedRowKeys: selected.map((item) => item.guid),
          onChange: (selectedRowKeys) => {
            onSelect?.(getStoreEntity(selectedRowKeys, [EdgeMachineSchema]));
          },
          getCheckboxProps: (record) => ({
            disabled: devicesInUse.includes(record.guid),
            title: devicesInUse.includes(record.guid) ? "In use" : "",
            "data-qa": "edge-hosts-selection",
            "data-qa-id": record.guid,
          }),
        }}
      />
    );
  }

  let userSelection = [];

  function Block({
    name,
    value,
    onChange,
    devicesInUse = [],
    extraItems = [],
    architecture,
    initialArchitecture,
    nodePoolsWrapperRef,
    ...rest
  }) {
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();

    async function onOpen() {
      const initialQuery = {
        limit: 25,
        architecture,
      };
      await dispatch(listingActions.initialize(moduleName, initialQuery));
      if (initialArchitecture && initialArchitecture === architecture) {
        dispatch(
          listingActions.addItems({ items: extraItems, module: moduleName })
        );
      }
      setSelected(value);
      userSelection = value;
      modalService.open({ name }).then(() => {
        onChange?.(
          userSelection.map((edgeHost) => {
            const initialHost = value.find(
              (host) => host.guid === edgeHost.guid
            );
            if (initialHost) {
              return {
                ...initialHost,
              };
            }
            return {
              hostUid: edgeHost.hostUid || edgeHost.metadata.uid,
              guid: edgeHost.guid,
              nicName: "auto",
              isConfigurable: true,
            };
          })
        );
      });
    }

    return (
      <>
        <Devices
          {...rest}
          name={name}
          value={value}
          onChange={onChange}
          onOpen={onOpen}
          nodePoolsWrapperRef={nodePoolsWrapperRef}
        />
        {modalService.data?.name === name ? (
          <Drawer
            title={t("Select edge hosts")}
            service={modalService}
            width="1032px"
            footerStyle={{ zIndex: 4 }}
            headerStyle={{ zIndex: 4 }}
          >
            <ListingModule module={moduleName} preventInitOnMount>
              <FilterBounds.Provider>
                <NegatePadding>
                  <EdgeHostFiltersComponent
                    top="-24px"
                    module={moduleName}
                    statusOptions={statusOptions}
                  />
                  <EdgeHostsTable
                    devicesInUse={devicesInUse}
                    onSelect={(selection) => {
                      setSelected(selection);
                      userSelection = selection;
                    }}
                    selected={selected}
                  />
                  <Blocks.Pagination
                    bottom="-24px"
                    isSticky={false}
                    pageSizeOptions={["10", "25", "50"]}
                  />
                </NegatePadding>
              </FilterBounds.Provider>
            </ListingModule>
          </Drawer>
        ) : null}
      </>
    );
  }

  return {
    moduleName,
    services: {
      modalService,
      listingActions,
    },
    Block,
  };
}
