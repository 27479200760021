import i18n from "i18next";
import { v4 as uuid } from "uuid";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import * as YAML from "yaml";

import store from "services/store";
import flags from "services/flags";
import { CloudAccountSchema, ClusterProfileSchema } from "utils/schemas";
import {
  ARCHITECTURE_TYPES,
  BAREMETAL_ENVS,
  COXEDGE_ENVIRONMENT,
  EDGE_NATIVE,
  MANAGED_TO_PURE_ENVIRONMENT,
  UPDATE_STRATEGIES,
} from "utils/constants";
import { FLAGS } from "utils/constants/flags";
import debounce from "redux-debounce-thunk";

import {
  getSelectedClusterProfile,
  getSelectedCloud,
  getSelectedEnvironmentType,
  isSupervizedEnvironment,
  getClusterRatePayload,
  getCoxEdgeNetworkSettings,
  hasMicroK8sPack,
} from "state/cluster/selectors/create";
import api from "services/api";
import historyService from "services/history";
import notifications from "services/notifications";
import ModalService from "services/modal";
import { WizardActions } from "modules/wizard/actions";
import { getCurrentStep } from "modules/wizard/selectors";
import {
  fetchAwsCloudConfigParams,
  fetchAzureNodeParams,
  fetchGoogleCloudNodeParams,
  fetchMaasCloudNodeParams,
  fetchBaremetalParams,
} from "../nodes";
import { getPayload } from "state/cluster/selectors/create";
import {
  regionsByProjectFetcher,
  clusterCreateProfileModule,
  onSpotWarningConfirm,
  appliancesKeyedFetcher,
  vsphereAppliancesFetcher,
  datacentersFetcher,
  cloudAccountsFetcher,
  eksKmskeysFetcher,
  cloudTemplateFetchers,
} from "state/cluster/services/create";
import { clusterRepaveStatusFetcher } from "state/cluster/services";
import { fetchBackupLocationsFetcher } from "state/backuplocations/actions";
import { sshKeysFetcher } from "state/sshKeys/services";
import {
  azureAZFetcher,
  azureInstanceTypesFetcher,
  gcpInstanceTypesFetcher,
} from "state/cluster/services/nodes";
import aws from "./flows/aws";
import azure from "./flows/azure";
import gcp from "./flows/gcp";
import cox from "./flows/cox";
import maas, { maasCloudForm } from "./flows/maas";
import vsphere from "./flows/vsphere";
import openstack from "./flows/openstack";
import tencent, { tencentCloudForm } from "./flows/tencent";
import { generateDomain, generateCoxDeployment } from "./utils";
import clusterFormActions, {
  createNodePoolValidator,
  nodePoolValidator,
} from "state/cluster/actions/create/form";
import { cloudAccountsSummaryFetcher, refreshClusters } from "../list/clusters";
import get from "lodash/get";
import set from "lodash/set";

import {
  isSecurityModeFips,
  getCurrentProjectUidFromUrl,
} from "state/auth/selectors";
import i18next from "i18next";
import {
  approveClusterRepave,
  onReviewRepave,
} from "state/cluster/actions/details";
import {
  extractNodePoolFieldsFromYAML,
  populateClusterConfig,
  populateNodePoolYAML,
  updateNodePoolNameInYAML,
  updateNodePoolSizeInYAML,
} from "utils/domain/nodepools";
import { DeviceSelection } from "components/common/NodePoolCloudFields/EdgeNativePoolConfigurationFields";
export const onCancelClusterModal = new ModalService("cancelModal");
export const onRepaveClusterModal = new ModalService("repaveModal");
export const onRepaveClusterDiffsModal = new ModalService("repaveDiffsModal");
export const reviewRepaveChangesModal = new ModalService(
  "reviewRepaveChangesModal"
);
export const reviewRepaveSummaryModal = new ModalService(
  "reviewRepaveSummaryModal"
);

export function showReviewModal(cluster) {
  return async (dispatch, getState) => {
    await dispatch(onReviewRepave(cluster));
    async function initiateRepave() {
      await dispatch(approveClusterRepave(cluster));

      const pathname = historyService?.location?.pathname || "";
      const isNodesPage = pathname.includes("/nodes");
      if (isNodesPage) {
        const projUid = getCurrentProjectUidFromUrl(getState());
        const projectUid = cluster?.specSummary?.projectMeta?.uid || projUid;
        await dispatch(
          clusterRepaveStatusFetcher.fetch({
            uid: cluster?.metadata?.uid,
            projectUid,
          })
        );
      } else {
        dispatch(refreshClusters());
      }
    }

    reviewRepaveSummaryModal
      .open({ clusterName: cluster.metadata.name })
      .then(({ step }) => {
        if (step === "review") {
          reviewRepaveChangesModal.open().then(initiateRepave);
        } else {
          initiateRepave();
        }
      });
  };
}

const subnetFields = [
  "controlPlaneSubnetName",
  "controlPlaneSubnetCidr",
  "controlPlaneSubnetSecurity",
  "workerSubnetName",
  "workerSubnetCidr",
  "workerSubnetSecurity",
];

const cloudFields = {
  aws: ["region", "ssh", "vpcid"],
  eks: [
    "region",
    "ssh",
    "vpcid",
    "controlPlane",
    "provider",
    "privateAccessCidrs",
    "publicAccessCidrs",
  ],
  vsphere: ["datacenter", "sshKeys", "ntpServers", "folder"],
  azure: [
    "region",
    "subscriptionId",
    "vnetName",
    "vnetResourceGroup",
    "vnetCidrBlock",
    "resourceGroup",
    "controlPlaneSubnet",
    ...subnetFields,
    "workerSubnet",
    "sshKey",
    "adminGroupObjectIDs",
    "apiServerLBStaticIP",
  ],
  aks: [
    "region",
    "subscriptionId",
    "vnetName",
    "vnetResourceGroup",
    "vnetCidrBlock",
    "resourceGroup",
    "controlPlaneSubnet",
    ...subnetFields,
    "workerSubnet",
    "sshKey",
  ],
  openstack: [
    "domain",
    "region",
    "project",
    "sshKeyName",
    "nodeCidr",
    "dnsNameservers",
    "network",
    "subnet",
  ],
  gcp: ["project", "region", "network"],
  gke: ["project", "region"],
  maas: ["domain"],
  libvirt: ["vip"],
  "edge-native": ["ip", "internalCIDR"],
  tke: ["region", "sshKeys", "vpcid", "publicSecurityGroup", "privateSubnet"],
  [COXEDGE_ENVIRONMENT.apiKey]: [
    "sshKeys",
    "coxEdgeLoadBalancerConfig",
    "coxEdgeWorkerLoadBalancerConfig",
  ],
};

export const CLUSTER_CREATION_STEPS = [
  {
    title: () => i18n.t("Basic Information"),
    id: "basic-info",
  },
  {
    title: () => i18n.t("Cluster Profile"),
    id: "infra-profile",
  },
  {
    title: () => i18n.t("Cluster Profile"),
    id: "parameters",
  },
  {
    title: () => i18n.t("Cluster Config"),
    id: "cluster-config",
  },
  {
    title: () => i18n.t("Nodes Config"),
    id: "nodes-config",
  },
  {
    title: () => i18n.t("Cluster Settings"),
    id: "policies",
  },
  {
    title: () => i18n.t("Review"),
    id: "review",
  },
];

export async function submit(data) {
  const state = store.getState();
  const cloudType = getSelectedCloud(state);

  const payload = getPayload(state);

  let response = null;

  const isCustom = data.clusterType === "custom";
  try {
    if (isCustom) {
      response = await api.post(
        `v1/spectroclusters/cloudTypes/${cloudType}`,
        payload
      );
    } else {
      response = await api.post(`v1/spectroclusters/${cloudType}`, payload);
    }
  } catch (error) {
    notifications.error({
      message: i18n.t("Something went wrong when creating the cluster"),
      description: error.message,
    });
  }

  if (response) {
    historyService.push(`/clusters/${response.uid}/overview`);
    notifications.success({
      message: `Cluster "${data.name}" has been created successfully. Deployment is in progress...`,
    });
  }
}

export const GET_DEFAULT_NODE_VALUES = ({ cpu = 4, memory = 8 } = {}) => ({
  size: 1,
  azs: [],
  instanceOption: "onDemand",
  instanceType: null,
  maxPricePercentage: 1,
  guid: uuid(),
  cpu,
  maxNodeSize: 3,
  minNodeSize: 1,
  minCPU: cpu,
  memory,
  minMem: memory,
  disk: 60,
  osType: "Linux",
  architecture: ARCHITECTURE_TYPES[0].value,
  isAutoscalerEnabled: false,
  storageAccountType: "",
  updateStrategy: UPDATE_STRATEGIES[0].value,
  flavor: "",
  resourcePool: "",
  edgeHosts: [],
  gpuVendor: "",
  gpuModel: "",
  taints: [],
  additionalLabels: [],
  nodeRepaveInterval: 0,
});

export function onCloudAccountSelect(value) {
  return async (dispatch, getState) => {
    const { preselectedProfile, profileType } = getState().forms?.cluster?.data;
    const isAddon = profileType === "add-on";

    const fetcher = isAddon
      ? cloudAccountsSummaryFetcher
      : cloudAccountsFetcher;
    const { cloudType, clusterType } = getState().forms?.cluster?.initialData;
    const pureEnvironmentType =
      MANAGED_TO_PURE_ENVIRONMENT[cloudType] || cloudType;
    const isCustom =
      getState().forms?.cluster?.initialData?.clusterType === "custom";

    let promise = () =>
      api.get(`v1/cloudaccounts/${pureEnvironmentType}/${value}`);
    if (isCustom) {
      promise = () =>
        api.get(`v1/cloudaccounts/cloudTypes/${pureEnvironmentType}/${value}`);
    }
    dispatch({
      type: "FETCH_SELECTED_CLOUD_ACCOUNT",
      promise: promise(),
      schema: CloudAccountSchema,
    });
    await promise();

    const cloudAccounts = fetcher.selector(getState()).result;

    const selectedCloudAcc = cloudAccounts.find(
      (acc) => acc.metadata.uid === value
    );

    let updates = {
      credential: value,
      hasDisablePropertiesRequest:
        selectedCloudAcc?.spec?.settings?.disablePropertiesRequest,
    };
    if (!preselectedProfile || isAddon) {
      updates = {
        ...updates,
        cloudType: getState().forms?.cluster?.initialData?.cloudType,
      };

      dispatch({
        type: "SET_SELECTED_CLOUD_TYPE",
        selectedCloud: selectedCloudAcc.kind,
      });
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates,
      })
    );
    dispatch(
      clusterFormActions.validateField({
        name: "credential",
        module: "cluster",
      })
    );
    if (cloudType === COXEDGE_ENVIRONMENT.apiKey || clusterType !== "edge") {
      store.dispatch(selectCredential(cloudType));
    }
  };
}

export function onChangeManagedActiveDirectory(value) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "managedAD",
        value,
      })
    );

    if (!value) {
      dispatch(
        clusterFormActions.validateField({
          name: "adminGroupObjectIDs",
          module: "cluster",
        })
      );
    }
  };
}

export function onChangeStaticPlacement(value) {
  return (dispatch, getState) => {
    const cloudType = getSelectedEnvironmentType(getState());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "staticPlacement",
        value,
      })
    );

    const effects = {
      aws: aws.onChangeStaticPlacement,
      azure: azure.onChangeStaticPlacement,
      gcp: gcp.onChangeStaticPlacement,
      openstack: openstack.onChangeStaticPlacement,
    };

    Object.keys(effects).includes(cloudType) &&
      dispatch(effects[cloudType](value));
  };
}

export function onChangeEnableEncryption(value) {
  return (dispatch, getState) => {
    const region = getState().forms.cluster.data.region;

    dispatch(eksKmskeysFetcher.key(region).fetch());

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "enableEncryption",
        value,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: "provider",
      })
    );
  };
}

function resetClusterConfigForm() {
  return (dispatch, getState) => {
    const selectedCloud = getSelectedEnvironmentType(getState());
    const formData = getState().forms.cluster.data;
    const cloudFieldsToReset = (cloudFields?.[selectedCloud] || []).reduce(
      (acc, fieldName) => {
        if (fieldName === "internalCIDR") {
          return acc;
        }
        if (Array.isArray(get(formData, fieldName))) {
          set(acc, fieldName, []);
        } else {
          set(acc, fieldName, "");
        }
        return acc;
      },
      {}
    );
    if (selectedCloud === "vsphere") {
      cloudFieldsToReset.ntpServers = [];
    }

    if (selectedCloud === "openstack") {
      cloudFieldsToReset.dnsNameservers = [];
    }

    if ([...BAREMETAL_ENVS, "maas", "vsphere", "tke"].includes(selectedCloud)) {
      cloudFieldsToReset.sshKeys = [];
    }

    if (["aks", "azure"].includes(selectedCloud)) {
      cloudFieldsToReset.adminGroupObjectIDs = [];
      cloudFieldsToReset.staticPlacement = false;
      cloudFieldsToReset.apiServerPrivate = false;
    }

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: cloudFieldsToReset,
      })
    );
  };
}

function getNodePoolsOverrides({
  isMaster = false,
  cloudType = "",
  containsMicroK8sPack = false,
}) {
  if (cloudType === "gke") {
    return { size: 3 };
  }

  if (cloudType === "maas" && isMaster && containsMicroK8sPack) {
    return { size: 3 };
  }
  return {};
}

function resetNodesConfigForm() {
  return (dispatch, getState) => {
    const isSupervized = isSupervizedEnvironment(getState());
    const cloudType = getSelectedCloud(getState());
    const networkSettings = getCoxEdgeNetworkSettings(getState());
    const containsMicroK8sPack = hasMicroK8sPack(getState());

    const workerPool = {
      poolName: "worker-pool",
      ...GET_DEFAULT_NODE_VALUES(),
      ...getNodePoolsOverrides({ cloudType }),
      domains: [generateDomain()],
      deployments: [generateCoxDeployment()],
      ...networkSettings,
    };

    const masterPool = {
      poolName: "control-plane-pool",
      ...GET_DEFAULT_NODE_VALUES(),
      ...getNodePoolsOverrides({
        cloudType,
        isMaster: true,
        containsMicroK8sPack,
      }),
      isMaster: true,
      domains: [generateDomain()],
      useControlPlaneAsWorker: cloudType === "edge-native",
      deployments: [generateCoxDeployment()],
      ...networkSettings,
    };

    let nodePools = [masterPool, workerPool];
    if (isSupervized) {
      nodePools = [workerPool];
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch({
      type: "CLUSTER_CREATE_RESET_ESTIMATED_RATE",
    });
  };
}

function initializeProfileStack() {
  return async function thunk(dispatch, getState) {
    await dispatch(fetchClusterProfilePacks());
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    const formData = getState().forms.cluster.data;
    let profiles = [{ ...selectedClusterProfile, type: "infra" }];

    if (formData.profileType === "add-on") {
      const addon = await dispatch(fetchAddonProfilePacks());
      profiles.splice(1, 0, { ...addon, type: "add-on" });
    }

    clusterCreateProfileModule.actions.initialize({
      profiles,
      options: {
        allowSystemProfiles:
          formData.cloudType !== COXEDGE_ENVIRONMENT.apiKey &&
          formData.clusterType === "edge" &&
          formData.cloudType !== "edge-native",
        isSystemProfileRequired:
          formData.cloudType === "libvirt" ||
          (formData.cloudType === "vsphere" && formData.clusterType === "edge"),
        allowInfraProfileRemoval: false,
        replaceProfileCallback: async (selectedProfile) => {
          await dispatch(
            onClusterProfileChange("clusterprofile", selectedProfile.guid)
          );
          dispatch(updateClusterProfileStepDescription());
        },
      },
    });

    let resolvedValuesPromise = api
      .get(
        `v1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs/resolvedValues`
      )
      .then((res) =>
        clusterCreateProfileModule.actions.updateResolvedValues([
          {
            resolved: res.resolved,
            uid: selectedClusterProfile.metadata.uid,
          },
        ])
      );
    dispatch({
      type: "FETCH_CLUSTER_PROFILE_RESOLVED_VALUES",
      promise: resolvedValuesPromise,
      schema: ClusterProfileSchema,
    });

    await resolvedValuesPromise;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "initializedProfileStack",
        value: true,
      })
    );
  };
}

function initializeClusterAndNodeConfigForm() {
  return async function thunk(dispatch, getState) {
    dispatch(resetClusterConfigForm());
    dispatch(resetNodesConfigForm());
  };
}

export function updatePolicyMenuSelection(newKey) {
  return function thunk(dispatch) {
    dispatch({
      type: "UPDATE_POLICY_MENU_SELECTION",
      newKey,
    });
  };
}

function parseMacroValue(initialValue) {
  if (!initialValue) {
    return "";
  }

  if (["false", false, "true", true].includes(initialValue)) {
    return !!initialValue;
  }

  if (/"([^"]*)"/.test(initialValue)) {
    return initialValue?.replaceAll('"', "");
  }

  if (!isNaN(Number(initialValue))) {
    return Number(initialValue);
  }

  return initialValue;
}

function getFieldtype(value) {
  const type = typeof value;
  const fieldTypes = {
    string: "Input",
    number: "InputNumber",
    boolean: "Checkbox",
  };

  return fieldTypes[type] || "Input";
}

export function getConfigMacros(yaml, clusterName, prevMacros = {}) {
  if (!prevMacros.CLUSTER_NAME?.value !== clusterName) {
    prevMacros.CLUSTER_NAME = { value: clusterName, disabled: true };
  }
  const prevMacrosKeys = Object.keys(prevMacros);
  const regex = /\${([^}]+)}/g;
  const matches = yaml.match(regex);
  const noDuplicates = new Set(matches);
  const cleanMatches = [...noDuplicates].reduce((accum, item) => {
    const [key, value] = item.slice(2, -1).split("=");
    const existingMacro = prevMacrosKeys?.includes(key) ? prevMacros[key] : {};
    const parsedValue = existingMacro?.value || parseMacroValue(value);

    accum[key] = {
      isOptional: /""/g.test(value),
      value: parsedValue,
      type: getFieldtype(parsedValue),
      disabled: existingMacro?.disabled,
      avoidResolve: existingMacro.avoidResolve,
      info: existingMacro.info,
    };
    return accum;
  }, {});
  return cleanMatches;
}

export function getNodePoolConfigMacros(yaml, clusterName, poolIndex) {
  const { cloudConfigMacros = {}, nodePools = {} } =
    store.getState().forms?.cluster?.data;
  const nodePool = nodePools[poolIndex] || {};
  const { nodePoolConfigMacros = {} } = nodePool;
  const cloudConfigs = Object.keys(cloudConfigMacros).reduce((accum, key) => {
    const macro = cloudConfigMacros[key];
    accum[key] = {
      ...macro,
      disabled: true,
    };
    return accum;
  }, {});
  const existingMacros = {
    ...nodePoolConfigMacros,
    ...cloudConfigs,
  };

  const macrosObj = getConfigMacros(yaml, clusterName, existingMacros);
  macrosObj.poolIndex = poolIndex;

  return macrosObj;
}

export const wizardActions = new WizardActions({
  formActions: clusterFormActions,
  fieldsToValidate() {
    const { preselectedProfile, clusterType, cloudType, profileType } =
      store.getState().forms?.cluster?.data;

    const getFields = () => {
      const selectedCloud = getSelectedCloud(store.getState());

      if (cloudType === "vsphere") {
        return [
          ["name", "credential"],
          ["layers"],
          [...(cloudFields[selectedCloud] || []), "appliance", "vip"],
          ["nodePools"],
        ];
      }
      if (cloudType === "eks") {
        return [
          ["name", "credential", "clusterType"],
          ["layers"],
          cloudFields[selectedCloud],
          ["nodePools"],
        ];
      }
      if (["libvirt", "edge-native"].includes(cloudType)) {
        return [
          ["name", "cloudType"],
          ["layers"],
          cloudFields[selectedCloud],
          ["nodePools"],
        ];
      }
      if (clusterType === "custom") {
        return [
          ["name", "credential"],
          ["layers"],
          ["cloudConfigMacros"],
          ["nodePools"],
        ];
      }

      return [
        ["name", "credential", "clusterType"],
        ["layers"],
        cloudFields[selectedCloud],
        ["nodePools"],
      ];
    };

    const fields = getFields();

    if (profileType === "add-on" || !preselectedProfile) {
      fields.splice(1, 1, ["clusterprofile", "layers"]);
    }

    return fields.reduce(
      (acc, field, index) => ({
        ...acc,
        [index]: field,
      }),
      {}
    );
  },
  getDescription({ id }) {
    const formData = store.getState().forms.cluster.data;

    if (id === "basic-info") {
      return formData.name;
    }

    if (id === "infra-profile") {
      return getSelectedClusterProfile(store.getState())?.metadata.name;
    }

    if (
      ["parameters", "cluster-config", "nodes-config", "policies"].includes(id)
    ) {
      return i18n.t("Completed");
    }
  },
  steps: CLUSTER_CREATION_STEPS,
  async onStepChange({ id }) {
    const {
      preselectedProfile,
      clusterType,
      profileType,
      initializedProfileStack,
      cloudType,
      nodePools,
      cloudConfigValues,
      name,
      credential,
      edgeHostsFetched,
    } = store.getState().forms?.cluster?.data;

    if (id === "basic-info") {
      if (cloudType === COXEDGE_ENVIRONMENT.apiKey || clusterType !== "edge") {
        profileType === "add-on"
          ? store.dispatch(cloudAccountsSummaryFetcher.fetch())
          : store.dispatch(cloudAccountsFetcher.fetch());
      }
    }

    if (id === "infra-profile") {
      if (!initializedProfileStack) {
        const selectedClusterProfile = getSelectedClusterProfile(
          store.getState()
        );
        const profiles = [{ ...selectedClusterProfile, type: "infra" }];
        const options = {
          cloudType: cloudType,
          editMode: false,
          showClusterProfileEmptyInstructions: true,
          allowSystemProfiles:
            cloudType !== COXEDGE_ENVIRONMENT.apiKey &&
            clusterType === "edge" &&
            cloudType !== "edge-native",
          isSystemProfileRequired:
            cloudType === "libvirt" ||
            (cloudType === "vsphere" && clusterType === "edge"),
          allowInfraProfileRemoval: false,
          mandatoryProfileTypes: ["infra", "cluster"],
          addProfileCallback: async (selectedProfile) => {
            await store.dispatch(
              onClusterProfileChange("clusterprofile", selectedProfile.guid)
            );
          },
          replaceProfileCallback: async (selectedProfile) => {
            await store.dispatch(
              onClusterProfileChange("clusterprofile", selectedProfile.guid)
            );
            store.dispatch(updateClusterProfileStepDescription());
          },
        };
        const profileModule = {
          options,
        };
        if (!!selectedClusterProfile) {
          profileModule.profiles = profiles;
        }
        clusterCreateProfileModule.actions.initialize(profileModule);
        store.dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: "initializedProfileStack",
            value: true,
          })
        );
      }
    }

    if (id === "parameters") {
      if (!initializedProfileStack) {
        await store.dispatch(initializeProfileStack());
        if (preselectedProfile) {
          store.dispatch(initializeClusterAndNodeConfigForm());
        }
      }
    }

    if (id === "cluster-config") {
      store.dispatch(sshKeysFetcher.fetch());
      if (clusterType === "custom") {
        const clusterTemplate = await store.dispatch(
          cloudTemplateFetchers.cluster.key(cloudType).fetch()
        );
        const yamlValue = populateClusterConfig({
          yaml: clusterTemplate,
          masterPoolName: nodePools[0].poolName || "control-plane-pool",
        });
        const cloudAccounts =
          cloudAccountsFetcher.selector(store.getState())?.result || [];
        const selectedAccount = cloudAccounts.find(
          (account) => account?.metadata?.uid === credential
        );
        const { credentials = {} } = selectedAccount?.spec;
        const cloudAccountMacros = Object.keys(credentials).reduce(
          (accum, key) => {
            accum[key] = {
              value: credentials[key],
              avoidResolve: true,
              info: i18next.t(
                "This macro has been filled from the cloud account."
              ),
              disabled: true,
            };
            return accum;
          },
          {}
        );

        if (!cloudConfigValues) {
          store.dispatch(
            clusterFormActions.onChange({
              name: "cloudConfigValues",
              value: yamlValue,
              module: "cluster",
            })
          );
          store.dispatch(
            clusterFormActions.onChange({
              name: "cloudConfigMacros",
              value: getConfigMacros(yamlValue, name, cloudAccountMacros),
              module: "cluster",
            })
          );
        } else {
          store.dispatch(
            clusterFormActions.onChange({
              name: "cloudConfigMacros.CLUSTER_NAME.value",
              value: name,
              module: "cluster",
            })
          );
        }
      }

      if (cloudType === "vsphere" && clusterType === "edge") {
        store.dispatch(vsphereAppliancesFetcher.fetch());
      }
      if (cloudType === "tke") {
        store.dispatch(tencentCloudForm.effects.fetchClusterConfigParams());
      }

      if (cloudType === COXEDGE_ENVIRONMENT.apiKey) {
        store.dispatch(cox.fetchClusterConfigParams());
      }
    }

    if (id === "nodes-config") {
      const selectedCloud = getSelectedEnvironmentType(store.getState());
      const policyMenuSelection =
        ["tencent", "edge-native"].includes(selectedCloud) ||
        cloudType === "gke"
          ? "scan-policies"
          : "manage-machines";

      createNodePoolValidator();

      if (clusterType === "custom") {
        if (!get(nodePools, "0.values")) {
          const controlPlaneTemplate = await store.dispatch(
            cloudTemplateFetchers.controlPlane.key(cloudType).fetch()
          );
          const yamlValue = populateNodePoolYAML({
            yaml: controlPlaneTemplate,
            ...nodePools[0],
          });

          store.dispatch(
            clusterFormActions.onChange({
              name: "nodePools.0.values",
              value: yamlValue,
              module: "cluster",
            })
          );

          store.dispatch(
            clusterFormActions.onChange({
              name: "nodePools.0.nodePoolConfigMacros",
              value: getNodePoolConfigMacros(yamlValue, name, 0),
              module: "cluster",
            })
          );
        } else {
          store.dispatch(
            clusterFormActions.onChange({
              name: "nodePools.0.nodePoolConfigMacros",
              value: getNodePoolConfigMacros(nodePools[0].values, name, 0),
              module: "cluster",
            })
          );
        }

        if (nodePools.length > 1) {
          if (!get(nodePools, "1.values")) {
            const workerTemplate = await store.dispatch(
              cloudTemplateFetchers.worker.key(cloudType).fetch()
            );

            const yamlValue = populateNodePoolYAML({
              yaml: workerTemplate,
              ...nodePools[1],
            });

            store.dispatch(
              clusterFormActions.onChange({
                name: "nodePools.1.values",
                value: yamlValue,
                module: "cluster",
              })
            );

            store.dispatch(
              clusterFormActions.onChange({
                name: "nodePools.1.nodePoolConfigMacros",
                value: getNodePoolConfigMacros(yamlValue, name, 1),
                module: "cluster",
              })
            );
          } else {
            store.dispatch(
              clusterFormActions.onChange({
                name: "nodePools.1.nodePoolConfigMacros",
                value: getNodePoolConfigMacros(nodePools[1].values, name, 1),
                module: "cluster",
              })
            );
          }
        }
      }

      const effects = {
        aws: () =>
          fetchAwsCloudConfigParams({
            type: "create",
            isSupervized: cloudType === "eks",
          }),
        azure: fetchAzureNodeParams,
        gcp: fetchGoogleCloudNodeParams,
        maas: () => fetchMaasCloudNodeParams(maasCloudForm),
        edge: fetchBaremetalParams,
        libvirt: fetchBaremetalParams,
        "edge-native": () => {
          return function thunk(dispatch) {
            if (!edgeHostsFetched) {
              dispatch(fetchBaremetalParams());
              dispatch(
                clusterFormActions.onChange({
                  module: "cluster",
                  name: "edgeHostsFetched",
                  value: true,
                })
              );
            }
          };
        },
        tencent: tencentCloudForm.effects.fetchNodesConfigParams,
        [COXEDGE_ENVIRONMENT.apiKey]: cox.fetchNodesConfigParams,
      };

      Object.keys(effects).includes(selectedCloud) &&
        store.dispatch(effects[selectedCloud]());

      store.dispatch(updatePolicyMenuSelection(policyMenuSelection));
    }

    if (id === "policies" && flags.has(FLAGS.BACKUP)) {
      await store.dispatch(fetchBackupLocationsFetcher.fetch());
      store.dispatch(setDefaultLocation());
    }
  },
});

function setDefaultLocation() {
  return (dispatch, getState) => {
    const state = getState();
    const currentLocation = state.forms.cluster?.data?.location;
    const locations =
      fetchBackupLocationsFetcher.selector(store.getState())?.result?.items ||
      [];
    const location =
      locations.find((l) => l.spec?.isDefault)?.metadata?.uid || null;

    if (currentLocation) {
      return;
    }

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "location",
        value: location,
      })
    );
  };
}

function parseProfilePacks(packs = [], selectedClusterProfile) {
  const packMapping = packs.reduce((accumulator, item) => {
    accumulator[item.metadata.name] = item;
    return accumulator;
  }, {});

  return {
    ...selectedClusterProfile,
    spec: {
      ...selectedClusterProfile.spec,
      published: {
        ...selectedClusterProfile.spec.published,
        packs: selectedClusterProfile.spec.published.packs.map((pack) => {
          return {
            ...pack,
            ...packMapping[pack.name],
          };
        }),
      },
    },
  };
}

export function fetchAddonProfilePacks() {
  return async function thunk(dispatch) {
    const [, profileUid] = store
      .getState()
      .router.location?.pathname?.split("create/");
    const addonProfile = await api.get(`v1/clusterprofiles/${profileUid}`);

    const promise = api.get(
      `v1/clusterprofiles/${addonProfile.metadata.uid}/packs?includePackMeta=schema,presets`
    );

    try {
      dispatch({
        type: "FETCH_CLUSTER_PROFILE_PACKS",
        promise,
        schema: ClusterProfileSchema,
      });

      const result = await promise;

      return parseProfilePacks(result.items, addonProfile);
    } catch (e) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to fetch the addon profile"
        ),
        description: e.message,
      });
    }
  };
}

export function fetchClusterProfilePacks() {
  return function thunk(dispatch, getState) {
    const selectedClusterProfile = getSelectedClusterProfile(getState());
    const selectedCloud = getSelectedCloud(getState());

    const promise = api
      .get(
        `v1/clusterprofiles/${selectedClusterProfile.metadata.uid}/packs?includePackMeta=schema,presets`
      )
      .then((res) => {
        return parseProfilePacks(res.items, selectedClusterProfile);
      });

    dispatch({
      type: "FETCH_CLUSTER_PROFILE_PACKS",
      promise,
      schema: ClusterProfileSchema,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "cloudType",
        value: selectedCloud,
      })
    );

    return promise;
  };
}

export function resetNextStepFields() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);
    const isNextStepCompleted =
      !!state.wizard.cluster.steps?.[currentStep + 1]?.description;

    if (!isNextStepCompleted) {
      return;
    }

    if (currentStep === 3) {
      dispatch(resetNodesConfigForm());
    }

    dispatch(resetForwardSteps());
  };
}

export function selectCredential(selectedCloud) {
  return async (dispatch, getState) => {
    dispatch(resetNextStepFields());

    const effects = {
      aws: aws.selectCredentialEffect,
      eks: aws.selectCredentialEffect,
      azure: azure.selectCredentialEffect,
      aks: azure.selectCredentialEffect,
      gcp: gcp.selectCredentialEffect,
      gke: gcp.selectCredentialEffect,
      maas: maas.selectCredentialEffect,
      vsphere: vsphere.selectCredentialEffect,
      openstack: openstack.selectCredentialEffect,
      tencent: tencent.selectCredentialEffect,
      [COXEDGE_ENVIRONMENT.apiKey]: cox.selectCredentialEffect,
    };

    Object.keys(effects).includes(selectedCloud) &&
      dispatch(effects[selectedCloud]());
  };
}

export function onAppliancesChange(value) {
  return (dispatch, getState) => {
    dispatch(resetNextStepFields());
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "appliance",
        value,
      })
    );
    dispatch(datacentersFetcher.fetch());
  };
}

export function selectRegion(region) {
  return (dispatch, getState) => {
    const selectedCloud = getState().cluster.create.selectedCloud;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "region",
        value: region,
      })
    );
    dispatch(resetNextStepFields());

    const effects = {
      aws: aws.selectRegionEffect,
      azure: azure.selectRegionEffect,
      gcp: gcp.selectRegionEffect,
      tencent: tencent.selectRegionEffect,
    };

    dispatch(effects[selectedCloud](region));
  };
}

function resetForwardSteps() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = getCurrentStep("cluster")(state);

    state.wizard.cluster.steps.forEach((_, index) => {
      if (index >= currentStep) {
        dispatch({
          type: "WIZARD__SET_STEP_DESCRIPTION",
          step: index,
          description: "",
          module: "cluster",
        });
      }
    });
  };
}

export function onClusterProfileChange(name, value) {
  return async (dispatch, getState) => {
    dispatch(clusterFormActions.onChange({ name, value, module: "cluster" }));
    return await dispatch(initializeClusterAndNodeConfigForm());
  };
}

export function updateClusterProfileStepDescription() {
  return (dispatch) => {
    dispatch(wizardActions.setDescription("cluster", "infra-profile"));
  };
}

export function setClusterSelectedLayer(selectedLayer) {
  return {
    type: "SET_CLUSTER_SELECTED_LAYER",
    selectedLayer: selectedLayer,
  };
}

export function backToClusterProfileSelection() {
  return {
    type: "FORM_ON_CHANGE",
    name: "clusterprofile",
    value: null,
    module: "cluster",
  };
}

export function addNodePool() {
  return (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.cluster.data;
    const clusterName = formData?.metadata?.name;
    const networkSettings = getCoxEdgeNetworkSettings(state);
    const poolName = `worker-pool-${formData.nodePoolsCount}`;
    const workerTemplate = cloudTemplateFetchers.worker
      .key(formData.cloudType)
      .selector(state)?.result;
    const yamlTemplate = updateNodePoolSizeInYAML(
      updateNodePoolNameInYAML(workerTemplate, poolName),
      1
    );
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools: [
            ...formData.nodePools,
            {
              poolName,
              ...GET_DEFAULT_NODE_VALUES(),
              domains: [generateDomain()],
              deployments: [generateCoxDeployment()],
              ...networkSettings,
              values: yamlTemplate,
            },
          ],
          nodePoolsCount: formData.nodePoolsCount + 1,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        name: "nodePools",
        module: "cluster",
      })
    );

    if (formData?.clusterType === "custom") {
      store.dispatch(
        clusterFormActions.onChange({
          name: `nodePools.${formData.nodePools.length}.nodePoolConfigMacros`,
          value: getNodePoolConfigMacros(
            yamlTemplate,
            clusterName,
            formData.nodePools.length
          ),
          module: "cluster",
        })
      );
    }

    dispatch(
      appliancesKeyedFetcher.key(`${formData.nodePools.length}`).fetch()
    );
  };
}

export function removeNodePool(index) {
  return (dispatch, getState) => {
    const state = getState();
    const formNodePools = state.forms.cluster.data?.nodePools;

    const newNodePools = [...formNodePools];
    newNodePools.splice(index, 1);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: newNodePools,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field?.startsWith(`nodePools.${index}`) ||
      error?.showInWizardFooter
        ? { ...error, result: null }
        : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );

    newNodePools.forEach((_, poolIndex) =>
      dispatch(appliancesKeyedFetcher.key(`${poolIndex}`).fetch())
    );
  };
}

export function copyFromMasterPool(workerPoolIdx) {
  return (dispatch, getState) => {
    const state = getState();
    const clusterFormData = state.forms.cluster.data;
    const formNodePools = clusterFormData?.nodePools || [];
    const nodePools = [...formNodePools];
    const nodePool = nodePools[workerPoolIdx];
    const masterPool = nodePools?.find((nodePool) => nodePool.isMaster);
    const getNodePoolFieldsPathsByIndex = (fieldNames, idx) =>
      fieldNames.map((fieldName) => `nodePools.${idx}.${fieldName}`);
    const cloudType = getSelectedEnvironmentType(getState());
    const cloudFieldsToKeep = {
      maas: ["minCPU", "minMem"],
    };

    const cloudFieldsToOmit = {
      azure: () => {
        const instanceTypes =
          azureInstanceTypesFetcher.selector(getState())?.result
            ?.instanceTypes || [];
        const azs = azureAZFetcher.selector(getState())?.result?.zoneList || [];
        const selectedMasterInstanceType = instanceTypes.find(
          (instanceType) => instanceType.type === masterPool.instanceType
        );

        return azs.length > 1 &&
          (selectedMasterInstanceType?.nonSupportedZones || []).length ===
            azs.length
          ? ["instanceType"]
          : [];
      },
      edge: () => ["edgeHosts"],
      libvirt: () => ["gpuSupport", "numGPUs", "gpuVendor", "gpuModel"],
      aws: () => {
        if (!clusterFormData?.staticPlacement) {
          return [];
        }

        const azsSubnets = (masterPool?.azs || []).map((az) => `subnet_${az}`);

        return ["azs", ...azsSubnets];
      },
    };

    const selectedCloudFieldsToOmit = cloudFieldsToOmit?.[cloudType];

    const generalFieldsToOmit = [
      "isMaster",
      "guid",
      "poolName",
      "size",
      "instanceOption",
      "updateStrategy",
      "useControlPlaneAsWorker",
      "minCPU",
      "minMem",
      "taints",
      "withTaints",
      "nodeRepaveInterval",
      ...((selectedCloudFieldsToOmit && selectedCloudFieldsToOmit()) || []),
    ].filter(
      (field) => !(cloudFieldsToKeep?.[cloudType] || []).includes(field)
    );

    const fieldsToCopy = omit(masterPool, generalFieldsToOmit) || {};

    nodePools[workerPoolIdx] = {
      ...nodePool,
      ...cloneDeep(fieldsToCopy),
    };

    const workerPoolFieldsToValidate = getNodePoolFieldsPathsByIndex(
      Object.keys(fieldsToCopy),
      workerPoolIdx
    );

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );
    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: [...workerPoolFieldsToValidate],
      })
    );
  };
}

export function onModalClose() {
  const formData = store.getState().forms.cluster.data;

  if (formData.name === "") {
    historyService.push("/clusters/overview");
    return;
  }

  onCancelClusterModal.open().then(() => {
    historyService.push("/clusters/overview");
  });
}

export function hasCloudAccounts(cloudAccounts) {
  return function checker(cloudType) {
    let environment = cloudType;
    if (BAREMETAL_ENVS.includes(cloudType)) {
      return true;
    }

    if (MANAGED_TO_PURE_ENVIRONMENT[cloudType]) {
      environment = MANAGED_TO_PURE_ENVIRONMENT[cloudType];
    }

    return cloudAccounts?.find((cloud) => cloud.kind === environment);
  };
}

export function onCloudSelectorChange(cloudType) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudAccounts = cloudAccountsFetcher.selector(state)?.result;
    const hasAccount = hasCloudAccounts(cloudAccounts)(cloudType);

    if (hasAccount) {
      dispatch(
        clusterFormActions.onChange({
          name: "cloudType",
          module: "cluster",
          value: cloudType,
        })
      );
    } else {
      historyService.push("/settings/cloudaccounts");
    }
  };
}

export function selectProject(project) {
  return (dispatch) => {
    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          project,
          region: "",
          network: "",
        },
      })
    );
    dispatch(regionsByProjectFetcher.fetch());
    dispatch(resetNextStepFields());
  };
}

function parseResultsErrors(results = []) {
  return results.reduce((acc, result) => {
    if (result.errors?.length) {
      const errors = result.errors.map((error) => ({
        ...error,
        displayName: result.displayName,
      }));
      return [...acc, ...errors];
    }
    return acc;
  }, []);
}

export function validateCluster() {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = getPayload(state);
    const selectedCloud = getSelectedCloud(state);
    const errors = await dispatch(
      clusterFormActions.validateForm({ module: "cluster" })
    );
    let response = null;

    if (errors && errors.length) return;

    if (state.forms.cluster.data.clusterType === "custom") {
      dispatch(wizardActions.nextStep("cluster"));
      return;
    }

    const promise = api.post(
      `v1/spectroclusters/${selectedCloud}/validate`,
      payload
    );

    dispatch({
      type: "VALIDATE_CLUSTER",
      promise,
    });

    try {
      response = await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t(
          "Something went wrong when trying to validate the cluster"
        ),
        description: error.message,
      });
      return;
    }

    const machinePools = response?.machinePools?.results || [];
    const packs = response?.packs?.results || [];
    const validationSuccess =
      response && isEmpty(machinePools) && isEmpty(packs);

    if (validationSuccess) {
      dispatch(wizardActions.nextStep("cluster"));
      return;
    }

    const machinePoolsErrors = parseResultsErrors(machinePools);
    const packsErrors = parseResultsErrors(packs);

    machinePoolsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
    packsErrors.forEach((error) => {
      notifications.error({
        message: error.displayName,
        description: error.message,
        duration: 0,
      });
    });
  };
}

export function validateUbuntuProPresets(ubuntuPack, state) {
  const isSecurityMode = isSecurityModeFips(state);
  const configuredPresets = YAML.parse(ubuntuPack?.values || "")?.kubeadmconfig
    ?.postKubeadmCommands;

  if (!isSecurityMode || !ubuntuPack?.fips) {
    return true;
  }

  if (isSecurityMode && ubuntuPack?.fips) {
    const isUbuntuProEnabled = configuredPresets?.find((preset) =>
      preset.includes("pro attach")
    );
    const isUbuntuProPasswordEntered = isUbuntuProEnabled
      ?.replace("pro attach")
      ?.trim()?.length;
    const isUbuntuProFipsEnabled = configuredPresets?.find((preset) =>
      preset.includes("pro enable fips")
    );
    const areRequiredPresetsConfitured =
      isUbuntuProEnabled &&
      isUbuntuProFipsEnabled &&
      isUbuntuProPasswordEntered;
    return areRequiredPresetsConfitured;
  }
}

export function onClusterConfigNextStep() {
  return (dispatch, getState) => {
    const state = getState();
    const currentStep = state.wizard.cluster?.currentStep;
    const steps = state.wizard.cluster?.steps;
    const currentStepParameters = steps[currentStep]?.id === "parameters";
    const ubuntuPack = getPayload(state).spec?.profiles[0]?.packValues?.find(
      (pack) => pack.name.includes("ubuntu")
    );
    const validateRequiredPresets = validateUbuntuProPresets(ubuntuPack, state);
    if (currentStepParameters && !validateRequiredPresets) {
      notifications.error({
        message: i18next.t(
          "Ubuntu Pro, Token and fips presets are required for FIPS compatibility."
        ),
      });
      return;
    }

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onNodesConfigNextStep() {
  return (dispatch, getState) => {
    const state = getState();
    const clusterFormData = state.forms.cluster?.data;
    const isSupervized = isSupervizedEnvironment(state);

    const workerPools = clusterFormData.nodePools.filter(
      (nodePool) => !nodePool.isMaster
    );

    const masterPool = clusterFormData.nodePools.find(
      (nodePool) => nodePool.isMaster
    );

    if (
      !masterPool?.useControlPlaneAsWorker &&
      workerPools.length > 0 &&
      workerPools.every((pool) => pool.instanceOption === "onSpot") &&
      !isSupervized
    ) {
      onSpotWarningConfirm.open().then(
        () => dispatch(onSpotWarningAllow()),
        () => dispatch(onSpotWarningIgnore())
      );
      return;
    }

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onSpotWarningAllow() {
  return (dispatch, getState) => {
    let nodePools = [...getState().forms.cluster.data.nodePools];
    const masterPoolIndex = nodePools.findIndex(
      (nodePool) => nodePool.isMaster
    );
    const masterPool = nodePools[masterPoolIndex];

    nodePools.splice(masterPoolIndex, 1, {
      ...masterPool,
      useControlPlaneAsWorker: true,
    });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onSpotWarningIgnore() {
  return (dispatch) => {
    dispatch(wizardActions.nextStep("cluster"));
  };
}

export function onClusterFormSubmit() {
  return async (dispatch) => {
    try {
      await dispatch(clusterFormActions.submit({ module: "cluster" }));
    } catch (err) {
      if ((err || []).find((error) => error.field === "layers")) {
        notifications.warn({
          message: i18n.t(
            "There are pack validation errors. Please fix them in order to be able to deploy"
          ),
        });
      }
    }
  };
}

export function onInstanceTypeChange(value, poolIndex) {
  return (dispatch, getState) => {
    const state = getState();
    const cloudType = getSelectedCloud(state);
    const resultTypes = {
      aws: state.cluster.details.cloudConfigParams,
      azure: azureInstanceTypesFetcher.selector(state).result,
      gcp: gcpInstanceTypesFetcher.selector(state).result,
      default: { instanceTypes: [] },
    };

    const result = resultTypes[cloudType] || resultTypes.default;

    const nodePools = [...state.forms.cluster.data.nodePools];
    const nodePool = nodePools[poolIndex];

    nodePools.splice(poolIndex, 1, {
      ...nodePool,
      instanceType: value,
      instancePrice:
        result?.instanceTypes?.find(
          (instanceType) => instanceType.type === value
        )?.price || 0,
      azs: [],
    });

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          nodePools,
        },
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: `nodePools.${poolIndex}.instanceType`,
      })
    );
  };
}

export function onAksSetPoolAsSystem(value, index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms.cluster.data.nodePools];

    nodePools[index].isSystemNodePool = value;

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    dispatch(
      clusterFormActions.validateField({
        module: "cluster",
        name: nodePools.map(
          (_, index) => `nodePools.${index}.isSystemNodePool`
        ),
      })
    );
  };
}

export function addNewTaint(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].taints.push({ key: "", value: "", effect: null });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
  };
}

export function removeTaint({ poolIndex, taintIndex }) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[poolIndex].taints.splice(taintIndex, 1);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );

    const errors = getState().forms.cluster.errors;
    const errorRemnants = errors.map((error) =>
      error?.field?.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      clusterFormActions.updateErrors({
        module: "cluster",
        errors: errorRemnants,
      })
    );
  };
}

export function addNewVirtualDevice(index) {
  return (dispatch, getState) => {
    const nodePools = [...getState().forms?.cluster?.data?.nodePools];
    nodePools[index].edgeHosts.push({ hostUid: "" });

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: "nodePools",
        value: nodePools,
      })
    );
  };
}

export const debouncedNodesFormChange = debounce(onNodesConfigFormChange, 2000);

function onNodesConfigFormChange(data) {
  return async (dispatch) => {
    let errors = [];
    const validations = nodePoolValidator.run(data.nodePools);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length && !BAREMETAL_ENVS.includes(data.cloudType)) {
      dispatch(fetchClusterRate());
    }
  };
}

export const onNodePoolNameChange = (poolIndex, name) => {
  return (dispatch, getState) => {
    const formData = getState().forms.cluster.data;
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.poolName`,
        value: name,
      })
    );

    const currentValues = formData.nodePools[poolIndex].values;
    const updatedValues = updateNodePoolNameInYAML(currentValues, name);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.values`,
        value: updatedValues,
      })
    );

    if (poolIndex === 0) {
      store.dispatch(
        clusterFormActions.onChange({
          name: "cloudConfigValues",
          value: populateClusterConfig({
            yaml: formData.cloudConfigValues,
            masterPoolName: name,
          }),
          module: "cluster",
        })
      );
    }
  };
};

export const onNodePoolSizeChange = (poolIndex, size) => {
  return (dispatch, getState) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.size`,
        value: size,
      })
    );

    const cloudType = getSelectedCloud(getState());
    const edgeHosts =
      getState().forms.cluster.data.nodePools[poolIndex].edgeHosts;

    if (cloudType === EDGE_NATIVE.apiKey) {
      dispatch(
        clusterFormActions.onChange({
          module: "cluster",
          name: `nodePools.${poolIndex}.edgeHosts`,
          value: Array.from({ length: size }, (_, index) => ({
            hostUid: "",
            hostName: "",
            ...(edgeHosts[index] ? edgeHosts[index] : {}),
          })),
        })
      );
    }

    const currentValues =
      getState().forms.cluster.data.nodePools[poolIndex].values;
    const updatedValues = updateNodePoolSizeInYAML(currentValues, size);

    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.values`,
        value: updatedValues,
      })
    );
  };
};

export const onNodePoolValuesChange = (poolIndex, values) => {
  return (dispatch, getState) => {
    dispatch(
      clusterFormActions.onChange({
        module: "cluster",
        name: `nodePools.${poolIndex}.values`,
        value: values,
      })
    );

    const updatedFields = extractNodePoolFieldsFromYAML(values);
    Object.keys(updatedFields).forEach((field) => {
      const value = updatedFields[field];
      if (typeof value !== "undefined") {
        dispatch(
          clusterFormActions.onChange({
            module: "cluster",
            name: `nodePools.${poolIndex}.${field}`,
            value,
          })
        );
      }
    });
  };
};

function fetchClusterRate() {
  return async (dispatch, getState) => {
    const state = getState();
    const payload = getClusterRatePayload(state);
    const cloudType = getSelectedCloud(state);
    const isCustom = state.forms?.cluster?.data?.clusterType === "custom";

    if (!cloudType || isCustom) {
      return;
    }

    const promise = api.post(
      `v1/spectroclusters/${cloudType}/rate?periodType=hourly`,
      payload
    );

    dispatch({
      promise,
      type: "FETCH_CREATE_CLUSTER_RATE",
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: err.message,
      });
      return;
    }

    return promise;
  };
}

export function initClusterWizard() {
  return async function thunk(dispatch, getState) {
    dispatch(wizardActions.initialize("cluster"));
    await dispatch(clusterFormActions.init({ module: "cluster" }));
    const { clusterType, profileType, cloudType } =
      getState().forms.cluster?.data;

    dispatch(
      DeviceSelection.services.listingActions.clearPersistedFilters(
        DeviceSelection.moduleName
      )
    );

    if (cloudType === COXEDGE_ENVIRONMENT.apiKey || clusterType !== "edge") {
      dispatch(cloudAccountsFetcher.fetch());
      profileType === "add-on"
        ? store.dispatch(cloudAccountsSummaryFetcher.fetch())
        : store.dispatch(cloudAccountsFetcher.fetch());
    }
  };
}
