import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import StatusCard from "components/styled/Status/new";
import ActionMenu from "components/common/ActionMenu";
import HealthStatusMessage from "components/styled/HealthStatusMessage";
import Link from "components/common/History/Link";
import ListingModule from "../components/edge/ListingModule";
import { Blocks, connectComponent } from "modules/list";
import { green, red, white } from "utils/constants/colors";
import * as ROUTES from "utils/constants/routes";
import { round } from "utils/number";
import {
  onMachineDelete,
  onHostPairingKeyEdit,
  onSelectApplianceTag,
} from "state/cluster/actions/list/edgemachines";
import { EDGE_MACHINES_MODULE } from "state/cluster/services/edgemachines";
import { TagsV2 } from "components/common/Tags";
import history from "services/history";
import MachineDeleteConfirmationModal from "../components/edge/MachineDeleteConfirmationModal";
import EditPairingKeyConfirmationModal from "../components/edge/EditPairingKeyConfirmationModal";
import { ADMIN_PREFIX_WITH_PROJECT } from "components/common/History/Route";
import { CLUSTERS } from "utils/constants/routes";
import { mergeColumns } from "modules/list/utils";
import Clipboard from "components/common/Clipboard";
import { processTagsForCopy } from "utils/parsers";
import { FilterBounds } from "pages/clusters/components/edge/EdgeMachinesFilters";
import { STACKING_CONTEXT_1, STACKING_CONTEXT_2 } from "utils/constants";

const HealthStatusWrapper = styled.div`
  max-width: 100px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ClipboardWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;

  .ant-table-cell:hover & {
    display: block;
  }
`;

const TagsWrapper = styled.div`
  position: relative;
`;

function sortStringBy({ a, b, getPath }) {
  const itemA = (getPath(a) || "").toLowerCase();
  const itemB = (getPath(b) || "").toLowerCase();
  if (itemA < itemB) {
    return -1;
  }
  if (itemA > itemB) {
    return 1;
  }
  return 0;
}

function sortNumberBy({ a, b, getPath }) {
  return getPath(a) - getPath(b);
}

export function useAppliancesColumns({
  onMachineDelete,
  onHostPairingKeyEdit,
  showCluster = true,
  showStatus = true,
  showActions = true,
  context,
  onSelectTag,
  highlightedTags = [],
  additionalColumnConfigs = {},
} = {}) {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns = [
      {
        key: "machineId",
        type: "textMultiple",
        title: t("Machine ID"),
        dataIndex: ["metadata", "name"],
        sorter: (a, b) =>
          sortStringBy({ a, b, getPath: (item) => item?.metadata?.name }),
      },
      context?.isAdmin && {
        key: "project",
        type: "textSingle",
        title: t("Project"),
        dataIndex: ["spec", "projectMeta", "name"],
        sorter: (a, b) =>
          sortStringBy({
            a,
            b,
            getPath: (item) => item?.spec?.projectMeta?.name,
          }),
      },
      showStatus && {
        key: "status",
        type: "badge",
        title: t("Status"),
        dataIndex: ["status", "state"],
        render: function renderStatus(state) {
          return <StatusCard status={state} />;
        },
        sorter: (a, b) =>
          sortStringBy({ a, b, getPath: (item) => item?.status?.state }),
      },
      {
        key: "health",
        title: t("Health"),
        type: "textSingle",
        dataIndex: ["status", "health"],
        render({ message, state }) {
          return (
            <HealthStatusWrapper>
              <HealthStatusMessage state={state} message={message} />
            </HealthStatusWrapper>
          );
        },
        sorter: (a, b) =>
          sortStringBy({
            a,
            b,
            getPath: (item) => item?.status?.health?.state,
          }),
      },
      {
        key: "tags",
        title: t("Tags"),
        dataIndex: ["metadata", "labels"],
        type: "tags",
        render(tags, _1, _2, options) {
          const { pinned, refs } = options;
          const stackRef =
            refs?.stackingContextsRefs.current?.[
              pinned ? STACKING_CONTEXT_2 : STACKING_CONTEXT_1
            ];
          const textTags = processTagsForCopy(tags).join(" ");

          return Object.keys(tags).length ? (
            <TagsWrapper>
              <TagsV2
                tags={tags}
                onSelect={onSelectTag}
                highlighted={highlightedTags}
                collisionBoundaryRef={refs?.wrap?.current}
                portalRef={stackRef}
              />
              <ClipboardWrapper
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
              >
                <Clipboard
                  text={textTags}
                  successMessage={t("Successfully copied tags to clipboard")}
                ></Clipboard>
              </ClipboardWrapper>
            </TagsWrapper>
          ) : (
            "-"
          );
        },
      },
      showCluster && {
        key: "cluster",
        type: "linkSingle",
        title: t("Cluster"),
        dataIndex: ["status", "inUseClusters", "0"],
        render(data) {
          if (!data) {
            return "-";
          }
          return (
            <Link
              to={generatePath(ROUTES.CLUSTERS.TAB_DETAILS, {
                id: data.uid,
                tab: "overview",
                clusterCategory: "clusters",
              })}
            >
              {data.name}
            </Link>
          );
        },
        sorter: (a, b) =>
          sortStringBy({
            a,
            b,
            getPath: (item) => item?.status?.inUseClusters?.[0]?.name,
          }),
      },
      {
        key: "cpu",
        type: "textSingle",
        title: t("CPU"),
        dataIndex: ["spec", "device", "cpu"],
        render({ cores }) {
          return cores ? `${cores} cores` : "-";
        },
        sorter: (a, b) =>
          sortNumberBy({
            a,
            b,
            getPath: (item) => item?.spec?.device?.cpu?.cores,
          }),
      },
      {
        key: "memory",
        type: "textSingle",
        title: t("Memory"),
        dataIndex: ["spec", "device", "memory"],
        render({ sizeInMB }) {
          return sizeInMB ? `${round(sizeInMB / 1024, 2)} GB` : "-";
        },
        sorter: (a, b) =>
          sortNumberBy({
            a,
            b,
            getPath: (item) => item?.spec?.device?.memory?.sizeInMB,
          }),
      },
      {
        key: "os",
        type: "textSingle",
        title: t("OS"),
        dataIndex: ["spec", "device", "os"],
        render({ family, version }) {
          if (!(family, version)) {
            return "-";
          }
          return [family, version].join(" ");
        },
        sorter: (a, b) =>
          sortStringBy({
            a,
            b,
            getPath: (item) => {
              const { family = "", version = "" } =
                item?.spec?.device?.os || {};
              return [family, version].join(" ");
            },
          }),
      },
      {
        title: t("IP Address"),
        key: "ipAddress",
        type: "textSingle",
        dataIndex: ["spec"],
        render: (spec) => {
          const nics = spec?.device?.nics || [];
          const ip = spec.host.hostAddress;
          const defaultNic = nics?.find((nic) => nic.isDefault);
          return <NoWrap>{ip || defaultNic?.ip || "-"}</NoWrap>;
        },
      },
      {
        key: "macAddress",
        type: "textSingle",
        title: t("MAC Address"),
        dataIndex: ["spec", "host", "macAddress"],
        render(macAddress) {
          return macAddress || "-";
        },
      },
      {
        key: "architecture",
        type: "textSingle",
        title: t("Architecture"),
        dataIndex: ["spec", "device", "archType"],
        sorter: (a, b) =>
          sortStringBy({ a, b, getPath: (item) => item?.metadata?.name }),
        render(archType = "") {
          return archType.toUpperCase() || "-";
        },
      },
      showActions && {
        key: "actions",
        type: "button",
        render: function renderActionColumn(data) {
          if (!onMachineDelete) {
            return null;
          }
          const { uid = "", name = "" } = data?.metadata;
          const projectUid = data?.spec?.projectMeta?.uid || "";

          return (
            <ActionMenu
              options={[
                {
                  label: t("Edit"),
                  key: "edit-pairing-key",
                  onClick: () => onHostPairingKeyEdit({ guid: data?.guid }),
                },
                {
                  label: t("Delete"),
                  color: red,
                  onClick: () => onMachineDelete({ uid, name, projectUid }),
                },
              ].filter(Boolean)}
            />
          );
        },
      },
    ].filter(Boolean);
    return mergeColumns(columns, additionalColumnConfigs);
  }, [
    t,
    context?.isAdmin,
    showStatus,
    showCluster,
    showActions,
    onMachineDelete,
    onHostPairingKeyEdit,
    onSelectTag,
    highlightedTags,
    additionalColumnConfigs,
  ]);
}

const additionalColumnConfigs = {
  machineId: {
    columnState: { locked: true, fixed: "left" },
  },
  actions: {
    columnState: { locked: true, fixed: "right" },
  },
};

function EdgeMachines({
  onMachineDelete,
  onHostPairingKeyEdit,
  context,
  onSelectApplianceTag,
  selectedTags,
}) {
  const columns = useAppliancesColumns({
    onMachineDelete,
    onHostPairingKeyEdit,
    context,
    onSelectTag: onSelectApplianceTag,
    highlightedTags: selectedTags,
    additionalColumnConfigs,
  });

  const onApplianceClick = useCallback(
    (row = { metadata: { uid: "" } }) => {
      let path = ROUTES.CLUSTERS.APPLIANCES_OVERVIEW;
      let params = {
        id: row.metadata.uid,
        tab: "overview",
      };

      if (context?.isAdmin && row?.spec?.projectMeta?.uid) {
        path = `${ADMIN_PREFIX_WITH_PROJECT}${CLUSTERS.APPLIANCES_OVERVIEW}`;
        params.projectUid = row?.spec?.projectMeta?.uid;
      }

      return history.push(generatePath(path, params));
    },
    [context?.isAdmin]
  );

  const { height } = FilterBounds.useBounds() || {};
  const offsetHeader = useMemo(() => {
    return height - 16;
  }, [height]);

  return (
    <>
      <ListingModule module={EDGE_MACHINES_MODULE} preventInitOnMount>
        <Blocks.Table
          showColumnManager={true}
          data-qa="edge-hosts-table"
          columns={columns}
          onRowClick={onApplianceClick}
          scroll={{ x: 1300 }}
          thOverflow={true}
          resizable={true}
          sticky={{ offsetHeader }}
        />
        <Blocks.Pagination pageSizeOptions={["10", "25", "50"]} />
      </ListingModule>
      <MachineDeleteConfirmationModal />
      <EditPairingKeyConfirmationModal />
    </>
  );
}

export default connect(
  (state) => ({
    selectedTags: state.list?.[EDGE_MACHINES_MODULE]?.query?.tags || [],
  }),
  {
    onMachineDelete,
    onHostPairingKeyEdit,
    onSelectApplianceTag,
  }
)(EdgeMachines);

const GreenPil = styled.div`
  background: ${green};
  border-radius: 10px;
  margin: 0px 8px;
  color: ${white};
  min-width: 22px;
  text-align: center;
`;

const AvailableAppliances = connectComponent(({ items = [] }) => {
  const readyItems = useMemo(() => {
    return items.filter((appliance) => appliance.status.state === "ready");
  }, [items]);

  if (readyItems.length === 0) {
    return null;
  }

  return <GreenPil>{readyItems.length}</GreenPil>;
});

export function ApplianceCount() {
  return (
    <ListingModule module={EDGE_MACHINES_MODULE} preventInitOnMount>
      <AvailableAppliances />
    </ListingModule>
  );
}
