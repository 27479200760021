import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css } from "styled-components";
import Icon from "components/ui/Icon";
import {
  faChevronDown,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Input, Tooltip } from "antd";
import { Checkbox } from "components/styled/Checkbox";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import EmptyResults from "components/ui/EmptyResults";
import isEqual from "fast-deep-equal";
import { usePrevious } from "utils/hooks";
import { TextButton } from "components/ui/Button";
import isEmpty from "lodash/isEmpty";

export interface SelectOption {
  label: React.ReactNode;
  value: string;
  info?: React.ReactNode;
}

const StyledTrigger = styled(DropdownMenu.Trigger)`
  padding: 0 12px;
  border-radius: 4px;
  background-color: rgba(31, 38, 60, 0.06);
  min-height: 32px;
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }

  &:focus {
    outline: none;
  }
`;

const StyledContent = styled(DropdownMenu.Content)`
  border-radius: 4px;
  border: 1px solid #dee1ea;
  background: #fff;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 8px 12px 0px rgba(9, 30, 66, 0.15);
  min-width: var(--radix-dropdown-menu-trigger-width);
  max-width: 320px;
  z-index: 999;
  position: relative;

  .ItemWrap {
    min-height: 32px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;

    &[data-state="checked"] {
      background: #dcdaf9;
    }

    &:hover,
    &:focus {
      background: rgba(31, 38, 60, 0.06);
      outline: none;
    }

    > * + * {
      margin-left: 8px;
    }

    &.Spaced {
      justify-content: space-between;
    }

    .Label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .InfoWrap {
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;

      > * + * {
        margin-left: 4px;
      }
    }
  }
`;

const Selection = styled.span`
  padding: 0px 4px;
  min-width: 16px;
  height: 16px;
  background-color: #7d77ca;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
`;

const SearchInputWrap = styled.div`
  padding: 4px;
  position: relative;

  input {
    padding-right: 32px;
  }

  .anticon {
    position: absolute;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    padding: 0px 8px;
  }
`;

const OptionsListWrap = styled(DropdownMenu.Group)`
  max-height: 320px;
  overflow-y: auto;
`;

const OptionWrap = styled.div<{ focused: boolean; checked: boolean }>`
  ${(props) =>
    props.checked &&
    css`
      background-color: #dcdaf9;
    `}

  ${(props) =>
    props.focused &&
    css`
      background: rgba(31, 38, 60, 0.06);
      outline: none;
    `}
`;

const ClearSelectiontWrap = styled.div`
  padding: 6px 12px;
  border-top: 1px solid #dee1ea;
  background: #f7f9ff;
`;

const ClearSelectionButton = styled(TextButton)`
  padding: 0;
  height: auto;
  color: #111626;
  font-size: 14px;
  line-height: 20px;

  :hover,
  :focus {
    color: #111626;
  }
`;

function HightlightSearchTerm({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string;
}) {
  if (!searchTerm) {
    return <>{text}</>;
  }

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, index) => {
        if (part.toLowerCase() === searchTerm.toLowerCase()) {
          return <strong key={index}>{part}</strong>;
        }

        return part;
      })}
    </>
  );
}

function CustomOptionItem({
  isFocused = false,
  checked = false,
  onCheckedChange,
  children,
  ...props
}: {
  isFocused: boolean;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  children: React.ReactNode;
}) {
  const optionRef = React.useRef(null);

  useEffect(() => {
    if (isFocused) {
      optionRef?.current?.scrollIntoViewIfNeeded({ behavior: "smooth" });
    }
  }, [isFocused]);

  return (
    <OptionWrap
      {...props}
      checked={checked}
      focused={isFocused}
      onClick={(ev) => {
        onCheckedChange?.(!checked);
      }}
      ref={optionRef}
    >
      {children}
    </OptionWrap>
  );
}

export default function Select({
  mode = "single",
  value,
  options = [],
  label,
  onChange,
  searchEnabled = false,
  onSearch,
  searchPlaceholder,
}: {
  options: SelectOption[];
  label: React.ReactNode;
} & (
  | {
      searchEnabled: true;
      onSearch: (searchTerm: string) => void;
      searchPlaceholder: React.ReactNode;
    }
  | {
      searchEnabled: false;
    }
) &
  (
    | {
        mode: "single";
        value?: SelectOption["value"];
        onChange: (value: SelectOption["value"]) => void;
      }
    | {
        mode: "multiple";
        value?: SelectOption["value"][];
        onChange: (value: SelectOption["value"][]) => void;
        searchEnabled?: boolean;
      }
  )) {
  const [opened, setOpened] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [orderedOptions, setOrderedOptions] = React.useState(options);
  const [focusedOptionIndex, setFocusedOptionIndex] = React.useState(0);
  const inputRef = React.useRef(null);
  const previouslyOpened = usePrevious(opened);
  const previousOptions = usePrevious(options);
  const { t } = useTranslation();

  useEffect(() => {
    if (!opened) {
      return;
    }

    const focusTimer = setTimeout(() => {
      inputRef?.current?.focus();
    }, 200);

    return () => clearTimeout(focusTimer);
  }, [opened]);

  useEffect(() => {
    if (mode === "multiple") {
      if (previouslyOpened || !isEqual(previousOptions, options)) {
        const valuesArray = Array.isArray(value) ? value : [value];
        const newOptions = options.slice();
        newOptions.sort((a, b) => {
          if (valuesArray.includes(a.label) && valuesArray.includes(b.label)) {
            return 0;
          }
          if (valuesArray.includes(a.label)) {
            return -1;
          }
          if (valuesArray.includes(b.label)) {
            return 0;
          }

          return 0;
        });

        setOrderedOptions(newOptions);
      }
    }
  }, [options, value, mode, previouslyOpened, previousOptions]);

  function onSetSearchTerm(value: string) {
    setSearchTerm(value);
    onSearch?.(value);
  }

  function renderSearchField() {
    if (!searchEnabled) {
      return null;
    }

    return (
      <SearchInputWrap>
        <Input
          ref={inputRef}
          value={searchTerm}
          placeholder={searchPlaceholder}
          onKeyDown={(ev) => {
            ev.stopPropagation();

            if (
              ev.key === "ArrowDown" &&
              focusedOptionIndex < orderedOptions.length - 1
            ) {
              ev.preventDefault();
              setFocusedOptionIndex((state) => state + 1);
            }

            if (ev.key === "ArrowUp" && focusedOptionIndex !== 0) {
              ev.preventDefault();
              setFocusedOptionIndex((state) => state - 1);
            }

            if (ev.key === "Enter") {
              const option = orderedOptions[focusedOptionIndex];

              if (mode === "single") {
                onChange(option.value);
                return;
              }

              if (value?.includes(option.value)) {
                onChange((value || []).filter((v) => v !== option.value));
              } else {
                onChange([...(value || []), option.value]);
              }
            }
          }}
          onChange={(ev) => {
            const value = ev.target.value;
            onSetSearchTerm(value);
            setFocusedOptionIndex(0);
          }}
        />
        {searchTerm ? (
          <Icon
            awesome={faTimes}
            onClick={() => {
              onSetSearchTerm("");
            }}
          />
        ) : null}
      </SearchInputWrap>
    );
  }

  function renderOptionInfo(option: SelectOption) {
    if (!option.info) {
      return null;
    }

    return (
      <Tooltip title={option.info} placement="right">
        <Icon awesome={faInfoCircle} />
      </Tooltip>
    );
  }

  function renderOptions() {
    if (!options.length) {
      return (
        <EmptyResults
          {...(searchTerm
            ? { description: t("No matching tags for this search query") }
            : {})}
        />
      );
    }

    if (mode === "single" && searchEnabled) {
      return (
        <div>
          {options.map((option, index) => {
            const isChecked = value?.includes(option.value);
            return (
              <CustomOptionItem
                key={option.value}
                className="ItemWrap Spaced"
                isFocused={index === focusedOptionIndex}
                checked={isChecked}
                onCheckedChange={() => {
                  inputRef?.current?.focus();
                  setFocusedOptionIndex(index);
                  onChange(option.value);
                }}
              >
                <span className="Label">{option.label}</span>
                <span className="InfoWrap">
                  {isChecked && <Icon awesome={faCheck} />}
                  {renderOptionInfo(option)}
                </span>
              </CustomOptionItem>
            );
          })}
        </div>
      );
    }

    if (mode === "single") {
      return (
        <DropdownMenu.RadioGroup value={value} onValueChange={onChange}>
          {options.map((option) => (
            <DropdownMenu.RadioItem
              className="ItemWrap Spaced"
              value={option.value}
              key={option.value}
            >
              <span className="Label">{option.label}</span>
              <span className="InfoWrap">
                <DropdownMenu.ItemIndicator>
                  <Icon awesome={faCheck} />
                </DropdownMenu.ItemIndicator>
                {renderOptionInfo(option)}
              </span>
            </DropdownMenu.RadioItem>
          ))}
        </DropdownMenu.RadioGroup>
      );
    }

    if (mode === "multiple") {
      return orderedOptions.map((option, index) => {
        const isChecked = value?.includes(option.value);
        const isFocused = focusedOptionIndex === index;
        const Component = searchEnabled
          ? CustomOptionItem
          : DropdownMenu.CheckboxItem;

        return (
          <Component
            key={option.value}
            className="ItemWrap"
            checked={isChecked}
            isFocused={isFocused}
            onCheckedChange={(checked) => {
              if (searchEnabled) {
                inputRef?.current?.focus();
                setFocusedOptionIndex(index);
              }

              if (checked) {
                onChange([...(value || []), option.value]);
              } else {
                onChange((value || []).filter((v) => v !== option.value));
              }
            }}
          >
            <Checkbox checked={isChecked} />
            <span className="Label">
              <HightlightSearchTerm
                text={option.label}
                searchTerm={searchTerm}
              />
            </span>
            <span className="InfoWrap">{renderOptionInfo(option)}</span>
          </Component>
        );
      });
    }
  }

  function renderSelection() {
    if (mode === "single" && value) {
      return 1;
    }

    if (mode === "multiple" && value) {
      return value.length;
    }

    return null;
  }

  const selection = renderSelection();

  return (
    <DropdownMenu.Root
      open={opened}
      onOpenChange={(open) => {
        if (open || mode === "single") {
          setOpened(open);
        }
      }}
    >
      <StyledTrigger>
        <span>{label}</span>
        {selection ? <Selection>{selection}</Selection> : null}
        <span>
          <Icon awesome={faChevronDown} />
        </span>
      </StyledTrigger>
      <DropdownMenu.Portal>
        <StyledContent
          sideOffset={5}
          onInteractOutside={() => {
            setOpened(false);
            onSetSearchTerm("");
            setFocusedOptionIndex(0);
          }}
          onClick={(ev) => {
            if (mode === "multiple") {
              ev.preventDefault();
              ev.stopPropagation();
            }
          }}
          onEscapeKeyDown={() => {
            setOpened(false);
            onSetSearchTerm("");
            setFocusedOptionIndex(0);
          }}
        >
          {renderSearchField()}
          <OptionsListWrap>{renderOptions()}</OptionsListWrap>
          {mode === "multiple" && !isEmpty(value) && (
            <ClearSelectiontWrap>
              <ClearSelectionButton onClick={() => onChange([])}>
                Clear Selection
              </ClearSelectionButton>
            </ClearSelectiontWrap>
          )}
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
