import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import {
  InfoCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Input as AntInput,
  Select as AntSelect,
  Radio,
  DatePicker,
  TimePicker,
  InputNumber,
  Upload,
  Tree,
  TreeSelect,
  Tooltip,
  Switch,
  Tag,
  AutoComplete as AntAutocomplete,
} from "antd";

import { TextButton } from "../Button";
import Slider from "./Slider";

import {
  connectField,
  Context as FormContext,
  useFormContext,
} from "modules/form";
import { FieldWrap, FieldLabel, Error } from "components/styled/Field";
import RadioGroup from "components/ui/RadioGroup";
import CardSelector from "components/ui/CardSelector";
import RoleSelector from "components/common/RoleSelector";
import MultipleFields from "components/ui/MultipleFields";
import SelectWithOptions from "./Select";
import {
  darkGray,
  blue,
  mediumLightGray,
  white,
  lightSilver,
  regentGray,
} from "utils/constants/colors";
import Cascader from "./Cascader";
import Cron from "./Cron";
import MultipleKeyValuePairs from "./MultipleKeyValuePairs";
import {
  Checkbox,
  CheckboxGroup as StyledCheckboxGroup,
} from "components/styled/Checkbox";
const Editor = React.lazy(() => import("components/common/Editor"));

const PasswordFieldContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-input {
    height: 40px;
  }
`;

const InputContainer = styled.div`
  width: 428px;
  display: flex;

  > div {
    margin-top: 0px;
  }
`;

const Eye = styled(EyeOutlined)`
  font-weight: bold;
  color: ${darkGray};
  font-size: 18px;
`;

const EyeInvisible = styled(EyeInvisibleOutlined)`
  font-weight: bold;
  color: ${darkGray};
  font-size: 18px;
`;

const SeePasswordButton = styled(TextButton)`
  margin: 0 0 0 10px;
  min-width: auto;
  display: flex;
  align-items: center;
`;

const RadioLabel = styled.div`
  display: inline-flex;
  align-items: center;
  .anticon.anticon-info-circle {
    margin-left: 8px;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 8px;
  font-size: 14px;
  vertical-align: middle;
`;

const Select = styled(AntSelect)`
  .ant-select-arrow.ant-select-arrow-loading {
    color: ${blue};
  }

  .ant-select-selection-item > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-select-selection-item .ant-select-selection-item-remove {
    min-width: 10px;
  }

  .ant-select-selection-overflow-item {
    > span {
      max-width: 100%;
    }
    .ant-tag {
      display: flex;
      align-items: center;
    }
  }
`;

const SwitchInfo = styled.div`
  max-width: 400px;
  font-size: 12px;
  color: ${mediumLightGray};
  margin-top: 8px;
`;

const StyledTagLabel = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${white};
`;

const TreeTitleInfo = styled.span`
  font-size: 11px;
  color: ${lightSilver};
`;

const FloatInputWrapper = styled.div`
  .ant-input-number,
  .ant-input-number-input {
    height: 32px;
  }

  .ant-input-number-group-wrapper {
    width: 100%;
    max-width: 428px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const TagLabel = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const MaxTagPlaceholder = ({ omittedValues, color }) => {
  if (!omittedValues?.length) {
    return null;
  }
  return (
    <Tooltip
      placement="right"
      color={color}
      title={omittedValues.map((item) => (
        <StyledTagLabel key={item.key}>{item.label}</StyledTagLabel>
      ))}
    >
      {`+ ${omittedValues.length} ...`}
    </Tooltip>
  );
};

export const Label = ({ label, required, description }) => {
  const { t } = useTranslation();
  return (
    <FieldLabel>
      {label} {!required && t("(Optional)")}
      {description && (
        <Tooltip title={description} placement="right">
          <StyledInfoCircleOutlined />
        </Tooltip>
      )}
    </FieldLabel>
  );
};

const HelperText = styled.div`
  font-size: 12px;
  color: ${regentGray};
  margin-top: 8px;
`;

export function Field(Component) {
  function FieldComponent(
    {
      required = true,
      showError = true,
      label,
      validation,
      description,
      hidden,
      helperText,
      ...rest
    },
    ref
  ) {
    let error = null;

    if (validation) {
      error = showError && validation[0];
    }

    return (
      <FieldWrap
        className={error && `ant-form-item-has-${error.status}`}
        hidden={hidden}
      >
        {label && (
          <Label label={label} required={required} description={description} />
        )}
        <Component
          ref={ref}
          {...rest}
          validateStatus={error && error.status}
          error={error}
        />
        {error ? <Error>{error.result}</Error> : null}
        {helperText ? <HelperText>{helperText}</HelperText> : null}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

function Input({ submitOnEnter = false, error, ...rest }, ref) {
  const context = useContext(FormContext);
  return (
    <AntInput
      ref={ref}
      onKeyPress={(ev) => {
        if (submitOnEnter && ev.key === "Enter") {
          context.onSubmit();
        }
      }}
      {...rest}
      validateStatus={error && error.status}
      error={error}
    />
  );
}

const NormalizeComponent = (Component) => {
  function NormalizedComponent({ validateStatus, ...rest }, ref) {
    function parseNativeEvent(ev) {
      if (!ev?.target) {
        return ev;
      }

      if (ev.target.type === "checkbox") {
        return ev.target.checked;
      }

      return ev.target.value;
    }

    return (
      <Component
        ref={ref}
        {...rest}
        onChange={(ev) => rest.onChange(parseNativeEvent(ev))}
        status={validateStatus}
      />
    );
  }

  NormalizedComponent.displayName = `NormalizedComponent(${Component.displayName})`;

  return React.forwardRef(NormalizedComponent);
};

const Tags = ({ onChange, ...rest }) => {
  const onTagChange = (value) => {
    if (value.length === 0) {
      onChange(value);
      return;
    }

    let lastValue = value[value.length - 1].trim();
    const parsedValue = [...value];

    if (lastValue.includes(" :") || lastValue.includes(": ")) {
      lastValue = lastValue
        .split(":")
        .map((tagParts) => tagParts.trim())
        .join(":");
    }

    parsedValue.splice(value.length - 1, 1, lastValue);
    onChange(parsedValue);
  };

  const invalidTags = rest?.error?.invalidTags || [];

  return (
    <Select
      mode="tags"
      maxTagCount={1000}
      open={false}
      tagRender={({ value, label, ...others }) => {
        const isInvalidTag = invalidTags.includes(value);

        return (
          <Tag
            color={!isInvalidTag ? "default" : "error"}
            style={{ padding: "0 4px 0 8px", fontSize: 14 }}
            key={value}
            title={label}
            {...others}
          >
            <TagLabel>{label}</TagLabel>
          </Tag>
        );
      }}
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      tokenSeparators={[",", " "]}
      {...rest}
      onChange={onTagChange}
      data-qa-mode="tags"
    />
  );
};

const CheckboxGroup = connectField(Field(StyledCheckboxGroup));
const ConnectedTreeSelect = connectField(Field(TreeSelect));
const ConnectedRadioGroup = connectField(Field(RadioGroup));

function TreeSelectWithDescription(
  { treeData, children, selectableParent, onBlur, ...rest },
  ref
) {
  const updateTreeDataTitles = useCallback(
    (data = []) => {
      if (!(Array.isArray(data) && data)) {
        return [];
      }

      return data.map((item) => ({
        ...item,
        title: getTitle(item),
        "data-qa": `${rest["data-qa"]}-option`,
        "data-qa-value": item.value,
        children: updateTreeDataTitles(item?.children || []),
      }));
    },
    [rest]
  );

  const data = useMemo(
    () => updateTreeDataTitles(treeData),
    [treeData, updateTreeDataTitles]
  );

  function getTitle({ title, description, info, icon }) {
    if (!title) {
      return null;
    }

    const itemTitle = info ? (
      <span>
        {title} <TreeTitleInfo>({info})</TreeTitleInfo>
      </span>
    ) : (
      title
    );

    return (
      <span>
        {description ? (
          <Tooltip title={description} placement="right">
            {itemTitle}
          </Tooltip>
        ) : (
          itemTitle
        )}
        {icon || null}
      </span>
    );
  }

  return (
    <ConnectedTreeSelect
      ref={ref}
      {...rest}
      {...(onBlur !== undefined && { onBlur: onBlur })}
      treeData={data}
      dropdownClassName={!selectableParent && "spectro-tree-selector"}
    >
      {children}
    </ConnectedTreeSelect>
  );
}

function RadioGroupWithDescription({ options, children, ...rest }) {
  const data = useMemo(() => {
    return options?.map((option) => ({
      ...option,
      label: getLabel(option),
    }));
  }, [options]);

  function getLabel({ label, description, ...rest }) {
    if (!label) {
      return null;
    }
    return (
      <RadioLabel {...rest}>
        <span>{label}</span>{" "}
        {description && (
          <Tooltip title={description} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </RadioLabel>
    );
  }

  return (
    <ConnectedRadioGroup options={data} {...rest}>
      {children}
    </ConnectedRadioGroup>
  );
}

export const NormalizedCheckbox = NormalizeComponent((props) => (
  <Checkbox {...props} checked={props.value} type="checkbox" />
));

const NormalizedSwitch = NormalizeComponent((props) => (
  <>
    <Switch
      {...props}
      checked={props.isInversed ? !props.value : props.value}
      type="checkbox"
      onChange={(val) => {
        props.onChange(props.isInversed ? !val : val);
      }}
    />
    {props.info && <SwitchInfo>{props.info}</SwitchInfo>}
  </>
));

const NormalizedTree = NormalizeComponent((props) => (
  <Tree {...props} checkedKeys={props.value} onCheck={props.onChange} />
));

const SeePasswordEye = ({ setCanSeePassword, canSeePassword }) => {
  return (
    <SeePasswordButton
      onClick={() => setCanSeePassword((prevState) => !prevState)}
    >
      {canSeePassword ? <EyeInvisible /> : <Eye />}
    </SeePasswordButton>
  );
};

const PasswordField = ({
  displayEyeIcon = true,
  allowAutofill = false,
  submitOnEnter = false,
  ...rest
}) => {
  const [canSeePassword, setCanSeePassword] = useState(false);
  const context = useFormContext();

  return (
    <PasswordFieldContainer>
      <InputContainer>
        <AntInput
          type={canSeePassword ? "text" : "password"}
          autoComplete={allowAutofill ? "current-password" : "new-password"}
          {...rest}
          onKeyPress={(ev) => {
            if (submitOnEnter && ev.key === "Enter") {
              context?.onSubmit?.();
            }
          }}
        />
      </InputContainer>
      {displayEyeIcon && (
        <SeePasswordEye
          setCanSeePassword={setCanSeePassword}
          canSeePassword={canSeePassword}
        />
      )}
    </PasswordFieldContainer>
  );
};

export function connectComponent(Component) {
  return connectField(Field(NormalizeComponent(Component)));
}

const NormalizedInput = NormalizeComponent(React.forwardRef(Input));
const ConnectedInput = connectField(Field(NormalizedInput), true);

const ConnectedTextArea = connectField(
  Field(
    NormalizeComponent((props) => <AntInput.TextArea rows="5" {...props} />)
  ),
  true
);

const NormalizedDatePicker = NormalizeComponent((props) => (
  <DatePicker {...props} value={props.value ? moment(props.value) : ""} />
));

const NormalizedTimePicker = NormalizeComponent((props) => (
  <TimePicker {...props} value={props.value ? moment(props.value) : ""} />
));

const NormalizedInputNumber = NormalizeComponent(
  ({ min, max, autoCorrect = false, value, onChange, onBlur, ...rest }) => {
    useEffect(() => {
      if (!value) {
        return;
      }

      if (autoCorrect) {
        const minValue = min || 1;

        if (minValue && value < minValue) {
          onChange(minValue);
        }

        if (max && value > max) {
          onChange(max);
        }
      }
    }, [value, onChange, min, max, autoCorrect]);

    const onInputBlur = useCallback(() => {
      if (!value && autoCorrect && typeof min === "number") {
        onChange(min);
      }

      onBlur && onBlur();
    }, [onChange, onBlur, value, min, autoCorrect]);

    return (
      <InputNumber
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onBlur={onInputBlur}
        {...rest}
      />
    );
  }
);

const InputFloatNumber = (props) => {
  return (
    <FloatInputWrapper>
      <NormalizedInputNumber stringMode step="any" {...props} />
    </FloatInputWrapper>
  );
};

const Empty = () => {
  return <></>;
};

const ErrorSection = ({ name }) => {
  const { t } = useTranslation();
  const context = useContext(FormContext) || {};
  const { fieldErrors = {} } = context;
  const errors = fieldErrors[name];

  return errors?.length ? (
    <Error>
      {errors[0]?.result ||
        t("Please resolve the errors in this section in order to continue")}
    </Error>
  ) : null;
};

const Blocks = {
  Input: ConnectedInput,
  TextArea: ConnectedTextArea,
  Tags: connectField(Field(Tags)),
  Select: connectField(Field(React.forwardRef(SelectWithOptions))),
  Autocomplete: connectField(Field(AntAutocomplete)),
  RadioGroup: RadioGroupWithDescription,
  CardSelector: connectField(Field(CardSelector)),
  Radio,
  CheckboxGroup,
  Checkbox: connectField(Field(NormalizedCheckbox)),
  DatePicker: connectField(Field(NormalizedDatePicker)),
  TimePicker: connectField(Field(NormalizedTimePicker)),
  InputNumber: connectField(Field(NormalizedInputNumber), true),
  Upload: connectField(Field(NormalizeComponent(Upload))),
  RoleSelector: connectField(Field(RoleSelector)),
  Tree: connectField(Field(NormalizedTree)),
  TreeSelect: React.forwardRef(TreeSelectWithDescription),
  MultipleInputs: connectField(Field(MultipleFields(ConnectedInput)), true),
  MultipleTextAreas: connectField(
    Field(MultipleFields(ConnectedTextArea)),
    true
  ),
  PasswordField: connectField(Field(NormalizeComponent(PasswordField)), true),
  Switch: connectField(Field(NormalizedSwitch)),
  Cascader: connectField(Field(Cascader)),
  Cron: connectField(Field(Cron)),
  Slider: connectField(Field(Slider)),
  MultipleKeyValuePairs: connectField(Field(MultipleKeyValuePairs)),
  Label,
  InputFloatNumber: connectField(Field(InputFloatNumber), true),
  Editor: connectField(Editor),
  Empty: Field(Empty),
  Error: ErrorSection,
};

export default Blocks;
