import React from "react";
import i18next from "i18next";
import api from "services/api";
import notifications from "services/notifications";
import store, { getStoreEntity } from "services/store";
import dataFetcher from "modules/dataFetcher";
import {
  ClusterProfileSchema,
  ClusterProfileTemplateSchema,
  ClusterSchema,
  ManifestSchema,
  PackSchema,
  PackVersionSchema,
} from "utils/schemas";
import { createTwoWaySyncer } from "utils/editor/formMode";
import { profileDifferentiator } from "modules/profileDifferentiator/utils";
import { extractUbuntuAdvantagePresetOptions } from "utils/yaml";
import pickBy from "lodash/pickBy";

const CLUSTER_PROFILES_LIMIT_SIZE = 20;

export function getSelectedPack(state) {
  const { selectedLayer, profiles } = state;
  let selectedPack =
    getStoreEntity(selectedLayer, PackSchema) ||
    getStoreEntity(selectedLayer, ManifestSchema);

  if (!selectedPack) {
    const profile = profiles.find((profile) =>
      profile.spec.published.packs.find((pack) => pack.guid === selectedLayer)
    );
    return (
      (profile?.spec?.published?.packs || []).find(
        (pack) => pack.guid === selectedLayer
      ) || getStoreEntity(selectedLayer, PackVersionSchema)
    );
  }

  return selectedPack;
}

function createSyncer(state) {
  const { selectedLayer, integrationFormValues, values } = state;
  let selectedPack = getSelectedPack(state);

  return createTwoWaySyncer({
    formData: integrationFormValues[selectedLayer],
    formMeta: selectedPack?.spec?.template?.parameters?.inputParameters || [],
    values: values[selectedLayer],
  });
}

const clusterProfileDataFetcher = dataFetcher({
  selectors: ["profileStack", "clusterProfile"],
  schema: ClusterProfileSchema,
  fetchData(_, uid) {
    return api.get(`v1/clusterprofiles/${uid}`).then((response) => ({
      ...response,
      specSummary: null,
    }));
  },
});

export function fetchClusterProfilePacks(profile) {
  return async function thunk(dispatch) {
    const promise = api
      .get(
        `v1/clusterprofiles/${profile.metadata.uid}/packs?includePackMeta=schema,presets`
      )
      .then((res) => {
        const packMapping = res.items.reduce((accumulator, item) => {
          accumulator[item.metadata.name] = item;
          return accumulator;
        }, {});

        return {
          ...profile,
          spec: {
            ...profile.spec,
            published: {
              ...profile.spec.published,
              packs: profile.spec.published.packs.map((pack) => {
                return {
                  ...pack,
                  ...pack.spec,
                  ...packMapping[pack.name],
                  spec: {
                    ...pack.spec,
                    ...packMapping[pack.name].spec,
                  },
                };
              }),
            },
          },
        };
      });

    dispatch({
      type: "FETCH_CLUSTER_PROFILE_PACKS",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

export function fetchProfileResolvedValues(uid) {
  return async function thunk(dispatch) {
    const promise = api.get(`v1/clusterprofiles/${uid}/packs/resolvedValues`);
    dispatch({
      type: "FETCH_CLUSTER_PROFILE_RESOLVED_VALUES",
      promise,
      schema: ClusterProfileSchema,
    });

    return promise;
  };
}

function parseManifest(manifest) {
  return {
    uid: manifest.metadata.uid,
    name: manifest.metadata.name,
    content: manifest.spec?.draft?.content || manifest.spec?.published?.content,
  };
}

function getManifestPromise(
  { profile, pack, manifest },
  isProfileFromCluster = false
) {
  const profileUid = profile?.metadata?.uid || profile?.profile?.metadata?.uid;

  let apiUrl = "";
  if (isProfileFromCluster) {
    const cluster = getStoreEntity(
      store.getState().cluster.details.currentClusterId,
      ClusterSchema
    );
    apiUrl = `v1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${manifest.uid}`;
  } else {
    apiUrl = `v1/clusterprofiles/${profileUid}/packs/${pack.packUid}/manifests/${manifest.uid}`;
  }

  const promise = api.get(apiUrl).then((manifest) => parseManifest(manifest));
  return store.dispatch({
    type: "FETCH_ATTACHED_MANIFEST",
    promise,
    schema: ManifestSchema,
  });
}

export default class ProfileStackActions {
  constructor({
    guid,
    onChange,
    dataFetcher,
    profileSelectorModal,
    profileRemovalConfirm,
    listingActions,
  }) {
    this.guid = guid;
    this.onChange = onChange;
    this.dataFetcher = dataFetcher;
    this.profileSelectorModal = profileSelectorModal;
    this.listingActions = listingActions;
    this.profileRemovalConfirm = profileRemovalConfirm;
  }

  get state() {
    return store.getState().profileStack[this.guid];
  }

  dispatch = (actionPayload) => {
    store.dispatch({
      ...actionPayload,
      guid: this.guid,
    });
  };

  initialize({ profiles = [], options = {} } = {}) {
    this.dispatch({
      type: "PROFILE_STACK_INIT",
      profiles,
      options,
    });
  }

  discardChanges = () => {
    this.dispatch({
      type: "PROFILE_STACK_RESET_STATE",
    });
  };

  selectLayer = (layerGuid, profileGuid) => {
    this.dispatch({
      type: "PROFILE_STACK_SELECT_LAYER",
      layerGuid,
    });

    this.selectProfile(profileGuid);

    const layer = getSelectedPack(this.state);
    let isIntegration =
      !!layer?.spec?.template?.parameters?.inputParameters?.length;

    let formMode = "packs";

    if (isIntegration) {
      formMode = "config";
    }

    if (layer?.spec?.readme) {
      formMode = "readme";
    }

    this.changeFormMode(formMode);
  };

  updateResolvedValues = (resolvedValues) => {
    this.dispatch({
      type: "UPDATE_RESOLVED_VALUES",
      resolvedValues,
    });
  };

  changeFormMode = (mode) => {
    this.dispatch({
      type: "PROFILE_STACK_UPDATE_FORM_MODE",
      mode,
    });
  };

  addProfile =
    (profileType = "add-on", cloudType = []) =>
    () => {
      const profileTypes = Array.isArray(profileType)
        ? profileType
        : [profileType];
      const cloudTypes = Array.isArray(cloudType) ? cloudType : [cloudType];
      this.profileSelectorModal.open({ profileType }).then(async () => {
        this.dispatch({
          type: "PROFILE_STACK_ADD_PROFILE_LOADING",
          loading: true,
        });
        const exists = this.state.profiles.find(
          (profile) => profile.guid === this.state.selectedProfile
        );

        let selectedProfile = getStoreEntity(
          this.state.selectedProfile,
          ClusterProfileSchema
        );

        if (exists) {
          notifications.warn({
            message: i18next.t(
              'Profile "{{profileName}}" has been already added once',
              { profileName: selectedProfile.metadata.name }
            ),
          });
          this.dispatch({
            type: "PROFILE_STACK_ADD_PROFILE_LOADING",
            loading: true,
          });
          return;
        }

        await store.dispatch(fetchClusterProfilePacks(selectedProfile));
        selectedProfile = getStoreEntity(
          this.state.selectedProfile,
          ClusterProfileSchema
        );

        this.dispatch({
          type: "PROFILE_STACK_ADD_PROFILE",
          profile: selectedProfile,
        });

        this.updateSelectedLayer(selectedProfile.metadata.uid);
        if (selectedProfile?.spec?.published?.type !== "add-on") {
          this.state?.options?.addProfileCallback?.({
            ...selectedProfile,
            type: profileType,
          });
        }
        this.onDetectPacksChanges();

        try {
          const resolvedValues = await store.dispatch(
            fetchProfileResolvedValues(selectedProfile.metadata.uid)
          );
          this.updateResolvedValues([
            {
              resolved: resolvedValues.resolved,
              uid: selectedProfile.metadata.uid,
            },
          ]);
        } catch (e) {}

        this.dispatch({
          type: "PROFILE_STACK_ADD_PROFILE_LOADING",
          loading: false,
        });
      });

      store.dispatch(
        this.listingActions.initialize(this.guid, {
          profileTypes: profileTypes,
          cloudTypes: cloudTypes,
          sort: [
            {
              field: "lastModifiedTimestamp",
              order: "desc",
            },
          ],
          limit: CLUSTER_PROFILES_LIMIT_SIZE,
        })
      );
    };

  attachProfile = async () => {
    const clusterCloudType = this.state.options.cloudType;
    const isVsphereEdge = this.state.options.isVsphereEdge;

    const exists = this.state.profiles.find(
      (profile) => profile.guid === this.state.selectedProfile
    );

    let selectedProfile = getStoreEntity(
      this.state.selectedProfile,
      ClusterProfileSchema
    );

    if (exists) {
      notifications.warn({
        message: i18next.t(
          'Profile "{{profileName}}" has been already added once',
          { profileName: selectedProfile.metadata.name }
        ),
      });
      return;
    }

    await store.dispatch(fetchClusterProfilePacks(selectedProfile));
    selectedProfile = getStoreEntity(
      this.state.selectedProfile,
      ClusterProfileSchema
    );

    this.dispatch({
      type: "PROFILE_STACK_ADD_PROFILE",
      profile: selectedProfile,
    });

    this.updateSelectedLayer(selectedProfile.metadata.uid);

    this.onDetectPacksChanges();

    try {
      const resolvedValues = await store.dispatch(
        fetchProfileResolvedValues(selectedProfile.metadata.uid)
      );
      this.updateResolvedValues([
        {
          resolved: resolvedValues.resolved,
          uid: selectedProfile.metadata.uid,
        },
      ]);
    } catch (e) {}

    notifications.info({
      message: (
        <>
          {i18next.t("Add-on profile {{profileName}} was added successfully.", {
            profileName: selectedProfile?.metadata?.name,
          })}
          <br />
          {clusterCloudType === "libvirt" || isVsphereEdge
            ? i18next.t(
                "You can now customize the newly attached cluster profile. Please confirm this action by clicking one of the buttons 'Update now' or 'Update later' below."
              )
            : i18next.t(
                "You can now customize the newly attached cluster profile. Please confirm this action by clicking the 'Save' button below."
              )}
        </>
      ),
      duration: 0,
    });
  };

  updateSelectedLayer(profileUid) {
    const profile = (this.state.profiles || []).find(
      (profile) => profile?.metadata?.uid === profileUid
    );
    const layer = profile?.spec?.published?.packs?.[0];
    const manifest = layer?.manifests?.[0];
    const selectedLayer = manifest?.guid || layer?.guid;
    this.selectLayer(selectedLayer, profile?.guid);
  }

  removeProfile = (profileToDelete) => {
    this.dispatch({
      type: "PROFILE_STACK_DELETE_PROFILE",
      profileToDelete,
    });

    if (profileToDelete?.guid === this.state.selectedProfile) {
      this.resetSelectedLayer();
    }
  };

  resetSelectedLayer = () => {
    this.dispatch({
      type: "PROFILE_STACK_SELECT_LAYER",
      selectedLayer:
        this.state.profiles?.[0]?.spec?.published?.packs?.[0]?.guid || null,
    });
  };

  keepProfile = (profileToKeep) => {
    this.dispatch({
      type: "PROFILE_STACK_UNDELETE_PROFILE",
      profileToKeep,
    });
  };

  setLayersErrors = (errors) => {
    this.dispatch({
      type: "PROFILE_STACK_SET_LAYERS_ERRORS",
      errors,
    });
  };

  replaceProfile = (profileGuid, profileType = "infra") => {
    const isEditMode = this.state.options.editMode;

    this.profileSelectorModal.open({ profileType }).then(async () => {
      this.dispatch({
        type: "PROFILE_STACK_ADD_PROFILE_LOADING",
        loading: true,
      });
      let selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );
      await store.dispatch(fetchClusterProfilePacks(selectedProfile));
      selectedProfile = getStoreEntity(
        this.state.selectedProfile,
        ClusterProfileSchema
      );
      this.dispatch({
        type: "PROFILE_STACK_REPLACE_PROFILE",
        newProfile: { ...selectedProfile, type: profileType },
        currentProfile: profileGuid,
      });
      this.dispatch({
        type: "PROFILE_STACK_ADD_PROFILE_LOADING",
        loading: false,
      });

      this.state?.options?.replaceProfileCallback?.({
        ...selectedProfile,
        type: profileType,
      });

      this.onDetectPacksChanges();
    });

    // TODO there has to be a better way
    const formCloudType = store.getState().forms.cluster?.data?.cloudType;
    let profileToSwap = getStoreEntity(
      profileGuid,
      ClusterProfileTemplateSchema
    );
    let profileCloudType = profileToSwap?.spec?.cloudType;

    if (!profileToSwap) {
      profileToSwap = getStoreEntity(profileGuid, ClusterProfileSchema);
      profileCloudType = profileToSwap.spec.published.cloudType;
    }

    const cloudType = isEditMode ? profileCloudType : formCloudType;
    const profileTypes =
      profileType === "system"
        ? ["system"]
        : profileType === "add-on"
        ? ["add-on"]
        : ["infra", "cluster"];

    store.dispatch(
      this.listingActions.initialize(this.guid, {
        profileTypes,
        cloudTypes: profileType === "system" ? undefined : [cloudType],
        sort: [
          {
            field: "lastModifiedTimestamp",
            order: "desc",
          },
        ],
        limit: CLUSTER_PROFILES_LIMIT_SIZE,
      })
    );
  };

  toggleExpand = (profileGuids) => {
    this.dispatch({
      type: "PROFILE_STACK_UPDATE_EXPANDED",
      profileGuids,
    });
  };

  selectProfile = (profileGuid) => {
    this.dispatch({
      type: "PROFILE_STACK_SELECT_PROFILE",
      profileGuid,
    });
  };

  backToProfileList = () => {
    this.dispatch({
      type: "PROFILE_STACK_VIEW_LIST",
    });
  };

  onValuesChange = (values) => {
    this.dispatch({
      type: "PROFILE_STACK_VALUE_CHANGE",
      values,
      layer: this.state.selectedLayer,
    });

    const params = createSyncer(this.state).populateForm();

    this.dispatch({
      type: "PROFILE_STACK_INTEGRATION_BATCH_UPDATES",
      layer: this.state.selectedLayer,
      params,
    });

    const presets = pickBy(
      extractUbuntuAdvantagePresetOptions(values),
      (val) => val !== "undefined"
    );
    this.onPresetsChange({ ubuntuAdvantage: presets });
  };

  onPresetsChange = (presets) => {
    this.dispatch({
      type: "PROFILE_STACK_PRESETS_CHANGE",
      presets,
      layer: this.state.selectedLayer,
    });
  };

  onIntegrationFieldChange = ({ name, value }) => {
    this.dispatch({
      type: "PROFILE_STACK_INTEGRATION_FIELD_VALUE_CHANGE",
      layer: this.state.selectedLayer,
      name,
      value,
    });

    this.onValuesChange(createSyncer(this.state).populateValues());
  };

  onManifestSelect = async ({
    manifestGuid,
    layerGuid,
    profileGuid,
    isAttachedManifest = true,
    editMode,
  }) => {
    const manifest = getStoreEntity(manifestGuid, ManifestSchema);
    const layer =
      getStoreEntity(layerGuid, PackVersionSchema) ||
      getStoreEntity(layerGuid, PackSchema);

    let entityGuid = isAttachedManifest ? manifestGuid : layerGuid;

    this.selectLayer(entityGuid, profileGuid);

    if (!this.state.values?.[entityGuid]) {
      let apiUrl = "";

      if (editMode) {
        const cluster = getStoreEntity(
          store.getState().cluster.details.currentClusterId,
          ClusterSchema
        );
        apiUrl = `v1/spectroclusters/${cluster.metadata.uid}/pack/manifests/${manifest.uid}`;
      } else {
        const currentProfile = getStoreEntity(
          this.state.selectedProfile,
          ClusterProfileSchema
        );

        apiUrl = `v1/clusterprofiles/${currentProfile.metadata.uid}/packs/${layer.name}/manifests/${manifest.uid}`;
      }

      const promise = api
        .get(apiUrl)
        .then((manifest) => parseManifest(manifest));

      await store.dispatch({
        type: "FETCH_ATTACHED_MANIFEST",
        promise,
        schema: ManifestSchema,
      });

      this.dispatch({
        type: "PROFILE_STACK_VALUE_CHANGE",
        values: getStoreEntity(manifestGuid, ManifestSchema).content,
        layer: entityGuid,
        isChangePersisted: true,
      });
    }
  };

  onDetectPacksChanges = async () => {
    const entities = store.getState().entities;
    const manifestUnknownChanges = profileDifferentiator.getPackChanges({
      targetProfiles: this.state.profiles,
      currentProfiles: this.state.initialProfiles,
      entities,
      values: this.state.values,
    }).unknownManifests;

    const promises = manifestUnknownChanges.reduce((accumulator, change) => {
      const currentManifestPromise = getManifestPromise(change.current, true);
      const targetManifestPromise = getManifestPromise(change.target);

      accumulator.push(currentManifestPromise, targetManifestPromise);
      return accumulator;
    }, []);
    await Promise.allSettled(promises);

    manifestUnknownChanges.forEach(({ current, target }) => {
      this.dispatch({
        type: "PROFILE_STACK_VALUE_CHANGE",
        values: getStoreEntity(target.manifest.guid, ManifestSchema)?.content,
        layer: target.manifest.guid,
      });

      this.dispatch({
        type: "PROFILE_STACK_VALUE_CHANGE",
        values: getStoreEntity(current.manifest.guid, ManifestSchema)?.content,
        layer: current.manifest.guid,
      });
    });
  };

  onVersionChange = async ({
    version,
    uid,
    profile,
    profileType = "infra",
  }) => {
    if (profile?.spec?.version === version) {
      return;
    }

    await store.dispatch(clusterProfileDataFetcher.fetch(uid));
    const newClusterProfile = clusterProfileDataFetcher.selector(
      store.getState()
    )?.result;
    const clusterProfilePacks = await store.dispatch(
      fetchClusterProfilePacks(newClusterProfile)
    );

    this.dispatch({
      type: "PROFILE_STACK_REPLACE_PROFILE",
      newProfile: { ...clusterProfilePacks, type: profileType },
      currentProfile: profile.guid,
    });

    this.updateSelectedLayer(uid);

    this.onDetectPacksChanges();

    const resolvedValues = await store.dispatch(
      fetchProfileResolvedValues(uid)
    );

    this.updateResolvedValues([
      {
        resolved: resolvedValues.resolved,
        uid,
      },
    ]);
  };
}
